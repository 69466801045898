import React, { useState } from "react";
import styled from "styled-components";
// import { HexColorPicker } from "react-colorful";

export default function ColorPickerUI({ color, setColor }) {
  return (
    <Wrapper>
      <div className="set-color">
        <form className="d-flex align-items-center justify-content-between mb-3">
          <label>Hex Code:</label>
          <input
            className="form-control"
            value={color}
            onChange={(e) => setColor(e.target.value)}
          />
        </form>
        {/* <HexColorPicker color={color ? color : ""} onChange={setColor} /> */}
        {/*<button className="btn btn-primary w-100">Choose Color</button>*/}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .set-color {
    height: 100vh;
    padding: 0 1.5rem 0 1.5rem;
    .react-colorful {
      height: 20.3125rem;
      width: 100%;
      margin-bottom: 1.5rem;
    }

    .react-colorful__saturation {
      // display: none;
      // border-radius: 0;
    }

    .react-colorful__hue-pointer {
      width: 1.5rem;
      height: 1.5rem;
      // display: none;
    }
    .react-colorful__hue {
      // display: none;
    }

    label {
      width: 5.25rem;
      color: #5d696f;
      font-weight: 500;
      font-size: 1rem;
      margin: 0;
    }

    .form-control {
      width: 15.1875rem;
      border-radius: 0.5rem;
    }

    .btn {
      padding: 0.875rem !important;
      font-size: 0.875rem !important;
      font-weight: 600;
      border-radius: 0.5rem;
    }
  }
`;
