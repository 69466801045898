import React, { useEffect, useState, useRef } from "react";
import uploadClient from "../src/helper/uploadClient";
import styled from "styled-components";
import {
  AppRegExp,
  calculateFileSize,
  GetFileIcon,
  truncateFileName,
} from "./components/task-update/utils";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import DownloadIcon from "mdi-react/DownloadIcon";
import CloseIcon from "mdi-react/CloseIcon";
import { connect } from "react-redux";
import { cloneDeep, size, toArray, isEmpty } from "lodash";
import {
  failureUploadFile,
  retryUploadFile,
  setUploadProgress,
  uploadFile,
  cancelUploadFile,
  closeUploadFile,
} from "./store/actions/uploadFileActions";
import {
  ChevronDownIcon,
  ReUploadIcon,
} from "./components/task-update/components/Icons";
import { useAsyncReference } from "./components/task-update/custom-hooks";
import {
  commentTypes,
  reduceUploadCount,
  sendComment,
} from "./store/actions/commentActions";
import store from "./store";
import watch from "redux-watch";
import eventBus from "./helper/EventBus";
import DialogModal from "./components/task-update/modals/DialogModal";

const canSendComment = (comment) => {
  let allFilesUploaded = true;
  if (comment.files.length) {
    allFilesUploaded = toArray(store.getState().uploadFileReducer.fileProgress)
      .filter((el) => el.taskId === comment.taskId)
      .every((el) => el.status);
    console.log("allFilesUploaded", allFilesUploaded);
  }

  return (
    allFilesUploaded &&
    !comment?.updatedAt &&
    comment?.error === 0 &&
    comment?.filesToUploadCount + comment?.alreadySavedfiles.length ===
      comment?.files.length
  );
};

const GlobalCommentRender = ({ comment, sendComment }) => {
  useEffect(() => {
    let unsubscribe;
    console.log("comment to watch", comment.id);

    if (canSendComment(comment)) {
      // console.log("canSendComment");
      sendComment([comment]);
    } else {
      let w = watch(
        store.getState,
        `commentReducer.globalComments.${comment.id}`
      );
      unsubscribe = store.subscribe(
        w((newVal, oldVal, objectPath) => {
          // console.log("%s changed from %s to %s", objectPath, oldVal, newVal);
          // console.log("new", newVal);
          if (newVal && canSendComment(newVal)) {
            console.log("send comment from watcher", newVal);
            sendComment([newVal]);
            if (unsubscribe) unsubscribe();
          }
        })
      );
    }

    return () => {
      console.log("comment left", comment);
      if (unsubscribe) unsubscribe();
    };
  }, []);

  /*
  const sendPendingComments = () => {
    const pendingComment =
      store.getState().commentReducer.globalComments[comment.id];
    if (canSendComment(pendingComment)) {
      sendComment([pendingComment]);
    }
  };

  eventBus.useCustomEventListener("socket-connected", sendPendingComments);
*/
  return null;
};

const FileRender = ({
  file,
  setUploadProgress,
  failureUploadFile,
  retryUploadFile,
  cancelUploadFile,
  closeUploadFile,
  uploadFile,
  setIsShow,
  reduceUploadCount,
}) => {
  let firstUpdate = useRef(true);
  const { current: fileRef } = useAsyncReference(file, true);

  useEffect(() => {
    let fileToUpload = store.getState().uploadFileReducer.fileProgress[file.id];

    if (fileToUpload) {
      if (
        fileToUpload.file &&
        fileToUpload.progress === 0 &&
        fileToUpload.error === 0 &&
        fileToUpload.cancel === 0 &&
        fileToUpload.stage === 0
      ) {
        console.log("upload", fileToUpload);
        uploadFile([{ ...fileToUpload }]);
        setIsShow(true);
      }
    }

    return () => console.log("file left");
  }, []);

  const fileSocketError = (err) => {
    if (fileRef.file && fileRef?.error === 0) {
      return failureUploadFile(fileRef.id);
    }
  };

  const handleFileProgress = (pro) => {
    if (fileRef.id && pro.id === fileRef.id) {
      let progress = Math.floor((pro.current / pro.size) * 100);
      setUploadProgress(fileRef.id, {
        progress,
        sizeUploaded: calculateFileSize(pro.current),
        loading: 0,
      });
    }
  };

  const handleUploadCancel = (id) => {
    console.log("cancel", id, fileRef.id, fileRef.file);
    if (fileRef.file && fileRef?.id === id) {
      cancelUploadFile(fileRef.id);
      // reduce upload amount in comment
      // reduceUploadCount(fileRef.id);
    }
  };

  const fileUploaderError = (error) => {
    console.log("file uploader error", error);
    if (fileRef.file && !fileRef.cloud_id && fileRef?.error === 0) {
      // may have to check this in the redux
      return failureUploadFile(fileRef.id);
    }
  };

  useEffect(() => {
    // if (firstUpdate.current && fileRef) {
    uploadClient.addEventListener("progress", handleFileProgress);
    uploadClient.addEventListener("socket-error", fileSocketError);
    uploadClient.addEventListener("canceled", handleUploadCancel);
    uploadClient.addEventListener("error", fileUploaderError);
    // }
    // firstUpdate.current = false;

    return () => {
      uploadClient.removeEventListener("socket-error", handleFileProgress);
      uploadClient.removeEventListener("progress", fileSocketError);
      uploadClient.removeEventListener("canceled", handleUploadCancel);
      uploadClient.addEventListener("error", fileUploaderError);
    };
  }, []);

  const handleCancelUpload = async (fileId) => {
    if (
      await DialogModal({
        title: "Cancel Uploading",
        description: "Are you sure you want to cancel this upload?",
        type: "warning",
        confirmMessage: "Yes",
        declineMessage: "No",
      })
    ) {
      console.log("id", fileId);
      uploadClient.dispatchEvent("cancel", fileId);
      cancelUploadFile(fileId);
      // reduceUploadCount(fileRef.id);
    }
  };

  return (
    <div
      className="file-other d-flex justify-content-between align-items-center"
      style={file?.error ? {} : {}}
    >
      <div className="d-flex align-items-center">
        <GetFileIcon file={file} />
        <div className="d-flex flex-column file-details">
          <span className="file-name">
            {truncateFileName({
              ...file,
              maxLength: 22,

              startLimit: 15,
              endLimit: -3,
            })}
          </span>
          {file.error ? (
            <span
              className="file-size"
              style={file.error ? { color: "#FC6D6C" } : {}}
            >
              Upload failed
            </span>
          ) : (
            <span className="file-size">{file.size}</span>
          )}
        </div>
      </div>

      <div className="d-flex align-items-center" style={{ color: "#A7B9CB" }}>
        <span className="show-on-hover">
          {file?.status && file ? (
            <CloseIcon
              className="download remove-file mr-3"
              onClick={() => closeUploadFile(file.id)}
            />
          ) : (
            <CloseIcon
              className="download remove-file mr-3"
              onClick={() => {
                handleCancelUpload(file.id);
              }}
            />
          )}
        </span>

        {file?.progress && !file?.status && !file.error ? (
          <Progress
            // onClick={() => handleUploadCancel(file.id)}
            type="circle"
            percent={file.progress}
            strokeWidth={10}
            width={26}
            symbolClassName="cancel"
            theme={{
              success: {
                symbol: ".",
                trailColor: "#fff",
                color: "#61DB99",
              },
              active: {
                symbol: ".",
                trailColor: "#fff",
                color: "#61DB99",
              },
              default: {
                symbol: ".",
                trailColor: "#fff",
                color: "#61DB99",
              },
            }}
          />
        ) : null}

        {file.error ? (
          <span
            onClick={() => {
              retryUploadFile(file.id);
              uploadFile([{ ...file }]);
            }}
          >
            <ReUploadIcon />
          </span>
        ) : null}
      </div>
    </div>
  );
};

const UploadProgress = ({
  fileProgress,
  user,
  setUploadProgress,
  uploadFile,
  failureUploadFile,
  retryUploadFile,
  closeUploadFile,
  globalComments,
  sendComment,
  cancelUploadFile,
  reduceUploadCount,
}) => {
  const [files, setFiles] = useState({});
  const [isShow, setIsShow] = useState(false);
  const uploadedFileAmount = size(fileProgress);
  const globalCommentsAmount = size(globalComments);
  const [showContents, setShowContents] = useState(false);

  /*const sendPendingComments = () => {
    const globalComments = store.getState().commentReducer.globalComments;
    if (Object.keys(globalComments).length) {
      const commentsToSend = toArray(globalComments).filter(
        (commentEl) =>
          /*commentEl.error === 0 &&
          !commentEl.updatedAt &&
          commentEl.filesToUploadCount >= commentEl.files.length*isEmpty(
            commentEl.files
          ) && canSendComment(commentEl)
      );
      if (commentsToSend?.length) {
        console.log("send pending comment", commentsToSend);
        sendComment(commentsToSend);
      }
    }
  };

  eventBus.useCustomEventListener("socket-connected", sendPendingComments);*/

  /*useEffect(() => {
    if (uploadedFileAmount) {
      // console.log(fileProgress);
      // will only render on size change
      let fileProgress = store.getState().uploadFileReducer.fileProgress;
      const fileToUpload = toArray(fileProgress).filter(
        (file) =>
          file.file &&
          file.progress === 0 &&
          file.error === 0 &&
          file.cancel === 0 &&
          file.stage === 0
      );
      if (fileToUpload?.length) {
        if (!isShow) setIsShow(true);
        console.log("upload file");
        uploadFile(fileToUpload);
      }
    }

    return () => {};
  }, [uploadedFileAmount]);
  */

  /*
  useEffect(() => {
    if (globalCommentsAmount) {
      //console.log(globalComments);
      const commentsToSend = toArray(globalComments).filter(
        (commentEl) =>
          commentEl.error === 0 &&
          !commentEl.updatedAt &&
          commentEl.filesToUploadCount >= commentEl.files.length
      );
      if (commentsToSend?.length) {
        // if (!isShow) setIsShow(true);
        // check if all files has been uploaded
        // console.log(commentsToSend);
        // sendComment(commentsToSend);
      }
    }

    return () => {};
  }, [globalCommentsAmount]);
  */

  //

  useEffect(() => {
    if (toArray(fileProgress).every((el, index) => el.status)) {
      if (!showContents) setIsShow(false);
    }
    setFiles({
      ...fileProgress,
    });
  }, [fileProgress, showContents]);

  /*
    useEffect(() => {
      if (firstUpdate.current) {
        setFile({ ...commentFile });
        if (commentFile?.file) {
          uploadClient.addEventListener("progress", (pro) => {
            // console.log(pro.id === commentFile.id);
            if (pro.id === commentFile.id) {
              let percentage = Math.floor((pro.current / pro.size) * 100);
              setFile({
                ...file,
                progress: percentage,
              });
            }
          });
          uploadFile();
        }
        firstUpdate.current = false;
        return () => {
          uploadClient.removeEventListener("progress", () => {});
        };
      }
    }, [commentFile]);
  
    const uploadFile = async () => {
      const folder_id = "";
      try {
        const res = await uploadClient.upload(file.file, {
          folder_id: "5f351216c56f413004f6d9fe",
          id: file.id,
        });
  
        console.log("done", res);
        // console.log("done", file);
  
        if (res) {
          const { cloud_id } = res;
          const body = {
            name: file.file.name,
            size: file.file.size,
            current_directory_id: folder_id,
            id: cloud_id,
            type: file.file.type,
            token,
          };
          //Rename file using it's id
          /*updateFileName(body).then((response) => {
              console.log("File renamed:", response);
            });
            
  
          // save files in backend
          const commentFile = {
            originalName: file.originalName,
            uniqueName: cloud_id,
            extension: file.extension,
            size: file.size,
            commentId,
            taskId,
          };
  
          socket.emit(
            type === "comment"
              ? "fx:add-comment-file"
              : "fx:add-comment-reply-file",
            {
              ...commentFile,
            },
            async (response) => {
              if (response.success) {
                setFile({
                  ...commentFile,
                });
              }
            }
          );
        }
      } catch (err) {
        const uploadError = uploadClient.getError();
        if (uploadError && uploadError.limited_space) {
          //Make Toast to alert user that space is limited
          showToast({
            message: {
              title: "Cloud Space exhausted",
            },
            type: "error",
          });
        }
        showToast({
          message: {
            title: "Unable to upload file",
          },
          type: "error",
        });
        console.log(uploadError);
        console.log("Upload client error:", err);
      }
    };
    */

  return (
    <Wrapper>
      <div
        className={`file-upload-progress mb-2 pt-0 shadow-sm ${
          !isShow ? "d-none" : ""
        }`}
      >
        <div className="d-flex justify-content-between align-items-center p-3 header  position-relative">
          <p className="h5 m-0">
            {uploadedFileAmount ? uploadedFileAmount : ""}
            {uploadedFileAmount > 1 ? " Uploads" : " Upload"}
          </p>

          <div
            className="actions"
            onClick={() => {
              setShowContents(!showContents);
            }}
          >
            <button className="btn p-0">
              <ChevronDownIcon
                className="text-white chev-icon p-cursor"
                style={!showContents ? { transform: "rotate(180deg)" } : {}}
              />
            </button>

            <CloseIcon
              className="text-white rounded-circle p-cursor"
              onClick={() => setIsShow(false)}
            />
          </div>
        </div>

        <div
          className="holder sleek-scrollbar white-scroll"
          style={!showContents ? { height: 0 } : {}}
        >
          {Object.keys(files).length
            ? toArray(files).map((file, index) =>
                !file.close && (file.id || file._id) ? (
                  <FileRender
                    key={file.id ? file.id : file._id}
                    file={file}
                    failureUploadFile={failureUploadFile}
                    setUploadProgress={setUploadProgress}
                    retryUploadFile={retryUploadFile}
                    closeUploadFile={closeUploadFile}
                    uploadFile={uploadFile}
                    cancelUploadFile={cancelUploadFile}
                    reduceUploadCount={reduceUploadCount}
                    setIsShow={setIsShow}
                  />
                ) : null
              )
            : null}
        </div>

        {
          <div className="d-none">
            {Object.keys(globalComments).length
              ? toArray(globalComments)?.map((comment) => (
                  <GlobalCommentRender
                    key={comment.id}
                    comment={comment}
                    sendComment={sendComment}
                  />
                ))
              : null}
          </div>
        }
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .file-upload-progress {
    border-radius: 0.25rem;
    box-shadow: 0px 5px 44px 0px #b2bdd33d;
    position: fixed;
    right: 2rem;
    bottom: 0.4rem;
    // height: 16.5rem;
    background: #ffffff;
    z-index: 100;
    width: 16rem;
    border-radius: 0.375rem;
    width: 25rem;
    max-height: 25rem;

    .header {
      border-radius: 0.25rem 0.25rem 0 0;
      height: 3.625rem;
      background: #012e54 !important;
      color: #fff;

      .actions {
        .chev-icon {
          margin-right: 1.4rem;
        }
      }
    }

    .holder {
      height: 19rem;
      background: #ffffff;
      overflow: auto;
      transition: height 0.2s;
      transition-timing-function: ease;

      .h5 {
        font-size: 0.9rem;
        font-weight: 600;
      }

      > div {
        margin-top: 1rem !important;
      }

      .file-other {
        width: calc(100% - 0.5rem);
        // border: 1px solid #dbe1e8;
        height: 3.0625rem;
        border-radius: 0.375rem;
        padding: 0.25rem 0.5rem 0.25rem 0.958125rem;
        padding-right: 1rem;
        position: relative;
        margin-right: 1rem;
        margin-bottom: 1rem;

        .file-icon {
          margin-right: 0.708125rem;
          width: 1.833125rem;
        }

        .file-details {
          .file-name {
            font-size: 0.875rem;
            font-weight: 600;
            color: #231f20;
          }

          .file-size {
            color: #788898;
            font-size: 0.75rem !important;
          }
        }

        .close,
        .download {
          color: #788898;
          width: 1.2rem;
          font-size: 0.8rem;
          margin-right: -2px;
          cursor: pointer;
        }

        .react-sweet-progress-circle {
          cursor: pointer;
        }

        .cancel {
          color: #788898;
          font-size: 0.75rem !important;
          cursor: pointer;
        }

        .show-on-hover {
          display: none;
        }

        &:hover {
          .show-on-hover {
            display: inline;
          }
        }
      }

      .sleek-scrollbar {
        &::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 4px #ddd;
          box-shadow: inset 0 0 4px #ddd;
          border-radius: 50px;
          background-color: #d8e6f5;
        }

        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
          background-color: #bdc9d7;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 50px;
          -webkit-box-shadow: inset 0 0 4px #ddd;
          box-shadow: inset 0 0 4px #ddd;
          background-color: #bdc9d7;
        }
      }
      .white-scroll {
        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar {
          background-color: transparent !important;
          box-shadow: none !important;
          border: none;
          width: 0.375rem !important;
          height: 0.375rem !important;
        }

        &::-webkit-scrollbar-thumb {
          box-shadow: none;
        }
      }
    }
  }
`;

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  token: state.userReducer.token,
  hasAccountExpired: state.userReducer.hasAccountExpired,
  fileProgress: state.uploadFileReducer.fileProgress,
  globalComments: state.commentReducer.globalComments,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setUploadProgress: (id, progress) =>
      dispatch(setUploadProgress(id, progress)),
    uploadFile: (files) => dispatch(uploadFile(files)),
    failureUploadFile: (fileId) => dispatch(failureUploadFile(fileId)),
    retryUploadFile: (fileId) => dispatch(retryUploadFile(fileId)),
    cancelUploadFile: (fileId) => dispatch(cancelUploadFile(fileId)),
    closeUploadFile: (fileId) => dispatch(closeUploadFile(fileId)),
    reduceUploadCount: (fileId) => dispatch(reduceUploadCount(fileId)),
    sendComment: (comments) => dispatch(sendComment(comments)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadProgress);
