import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import Topbar from "../topbar/Topbar";
import Sidebar from "../sidebar/Sidebar";
import Spinner from "../global/Spinner";
import Project from "./Project";
import AddProjectModal from "./AddProjectModal";
import EditProjectModal from "./EditProjectModal";
import { socket } from "../../sockets";
import {
  deleteProject,
  getProjectsIBelongTo,
} from "../../services/projectServices";
import { getClients } from "../../services/clientServices";
import { getTeamsICreated } from "../../services/teamServices";
import { getCompanyUsers } from "../../services/userServices";
import { removeModalElements } from "../../utils";
import ProjectModal from "../task-update/modals/ProjectModal";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { setUploadFile } from "../../store/actions/uploadFileActions";
import "./../../../src/assets/scss/new-ui.scss";
import PlusIcon from "mdi-react/PlusIcon";
import TextLongIcon from "mdi-react/TextIcon";
import { InputReload, SearchIcon } from "../task-update/components/Icons";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import DialogModal from "../task-update/modals/DialogModal";
import eventBus from "../../helper/EventBus";
import { openPageSideBar, showToast } from "../task-update/utils";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      projectsClone: [],
      teams: [],
      clientList: [],
      employeesList: [],
      editProjectData: "",
      projectToDelete: null,
      isLoadingProjects: true,
      isAddModalOpen: false,
      isEditModalOpen: false,
      canDeleteProjectFolder: true,
      projectLabels: [],
      filtedProjects: [],
    };
  }

  async componentDidMount() {
    try {
      const token = this.props.token;
      const [projectsResponse, clientsResponse, teamsResponse, usersResponse] =
        await Promise.all([
          getProjectsIBelongTo(token),
          getClients(token),
          getTeamsICreated(token),
          getCompanyUsers(token),
        ]);

      let projects =
        projectsResponse.projects?.filter(
          (project) => project.types === "assets"
        ) || [];
      const labels = projects.reduce((acc, project) => {
        if (project.labels && !acc.includes(project.labels)) {
          acc.push(project.labels);
        }
        return acc;
      }, []);

      this.setState({
        projects: projects,
        projectsClone: projects,
        filtedProjects: projects,
        clientList: clientsResponse.clients || [],
        teams: teamsResponse.teams || [],
        employeesList: usersResponse.users || [],
        isLoadingProjects: false,
        projectLabels: labels,
      });

      this.handleProjectEventsReceived();
    } catch (error) {
      console.log(error);
    }
  }

  handleProjectEventsReceived = () => {
    socket.on("fx-added-to-project", ({ project }) => {
      if (project) {
        this.updateProjectsList(project);
      }
    });

    socket.on("fx-removed-from-project", ({ projectId }) => {
      if (projectId) {
        let projectsClone = this.state.projectsClone;
        projectsClone = projectsClone.filter(
          (project) => project._id !== projectId
        );
        this.setState({ projectsClone, projects: projectsClone });
      }
    });

    socket.on("fx-project-updated", ({ project }) => {
      if (project) {
        this.updateProjectsList(project);
      }
    });

    socket.on("fx-project-deleted", ({ projectId }) => {
      if (projectId) {
        let projectsClone = this.state.projectsClone;
        projectsClone = projectsClone.filter(
          (project) => project._id !== projectId
        );
        this.setState({ projectsClone, projects: projectsClone });
      }
    });
  };

  selectProjectToDelete = (project) => {
    this.setState({ projectToDelete: project });
  };

  editProject = (project) => {
    this.setState({ editProjectData: project });
    this.handleModal("edit");
  };

  handleDeleteProject = async (projectToDelete) => {
    const res = await DialogModal({
      title: "Delete Project",
      description: `Are you sure, you want to delete?`,
      type: "warning",
      form: [
        {
          label: "Delete Project Folder",
          name: "canDeleteProjectFolder",
          value: false,
          type: "option",
        },
      ],
    });
    if (res) {
      let { projectsClone } = this.state;
      const selectedProjectId = projectToDelete ? projectToDelete._id : null;
      const selectedFileSystemId =
        projectToDelete && projectToDelete.project_folder_id
          ? projectToDelete.project_folder_id
          : null;
      const token = this.props.token;
      const id = selectedFileSystemId;

      deleteProject(
        selectedProjectId,
        id,
        token,
        res.canDeleteProjectFolder
      ).then((response) => {
        if (response.success) {
          projectsClone = projectsClone.filter(
            (project) => project._id !== selectedProjectId
          );
          this.setState({
            projectsClone,
            projects: projectsClone,
            // canDeleteProjectFolder: true,
            projectToDelete: null,
          });

          showToast({
            message: {
              title: "Project deleted",
            },
            type: "success",
          });
        }
      });
    }
  };

  updateProjectsList = (project, isProjectModalOpen = false) => {
    let { projects, projectsClone, isAddModalOpen, isEditModalOpen } =
      this.state;
    const id = project && project._id;
    if (id) {
      let isExists = false;
      for (let index = projectsClone.length - 1; index >= 0; --index) {
        if (projectsClone[index] && projectsClone[index]._id === id) {
          projectsClone.splice(index, 1);
          projectsClone.splice(index, 0, project);
          isExists = true;
          break;
        }
      }
      if (isExists) {
        this.setState({ projectsClone, projects: projectsClone });
      } else {
        this.setState({
          projectsClone: projectsClone.concat(project),
          projects: projectsClone.concat(project),
        });
      }
    }

    if (isProjectModalOpen) return;

    if (isAddModalOpen) {
      this.handleModal("add");
    } else if (isEditModalOpen) {
      this.handleModal("edit");
    }
  };

  handleModal = (modal) => {
    const { account_type } = this.props.user;
    // removeModalElements();
    if (modal === "add") {
      const isAddModalOpen = !this.state.isAddModalOpen;
      this.setState({ isAddModalOpen });
    } else if (modal === "edit") {
      const isEditModalOpen = !this.state.isEditModalOpen;
      this.setState({ isEditModalOpen });
    }
  };

  handleSearchProjects = (e) => {
    if (e) e.preventDefault();
    const value = e ? e.target.value.trim() : "";
    let { projects, projectsClone } = this.state;

    if (value) {
      projects = projectsClone.filter((project) => {
        if (project.name.toLowerCase().includes(value)) {
          return project;
        }
      });
    } else {
      projects = projectsClone;
    }
    this.setState({
      filtedProjects: projects,
    });
  };

  toggleCanDeleteProjectFolder = () => {
    const { canDeleteProjectFolder } = this.state;
    this.setState({
      canDeleteProjectFolder: !canDeleteProjectFolder,
    });
  };

  resetDeleteModal = (e) => {
    if (e.target !== e.currentTarget) return;
    this.setState({ canDeleteProjectFolder: true, projectToDelete: null });
  };

  handleFileUpload = async ({ folder_id, response, project }) => {
    console.log("handle file upload", { folder_id, response, project });
    if (project.files && project.files.length) {
      let filesToUpload = {};

      for (const file of project.files) {
        const id = uuidv4();

        // console.log(file);
        filesToUpload = {
          ...filesToUpload,
          [id]: {
            id,
            ...cloneDeep(file),
            progress: 0,
            cancel: 0,
            status: 0,
            close: 0,
            stage: 0,
            projectId: project._id,
            folder_id: project.project_folder_id,
            error: 0,
            token: this.props.token,
          },
        };
      }
      console.log(filesToUpload);
      this.props.setUploadFile({ ...filesToUpload });
      return;
    }
  };

  handleFilterByLabel = (e) => {
    let label = e.target.value;
    let { projects } = this.state;
    console.log(projects);
    let filteredProjects = [...projects];
    if (label) {
      filteredProjects = projects.filter((project) => project.labels === label);
    }

    console.log(filteredProjects);
    this.setState({ filtedProjects: [...filteredProjects] });
  };

  render() {
    const {
      projects,
      editProjectData,
      employeesList,
      clientList,
      teams,
      isLoadingProjects,
      isEditModalOpen,
      isAddModalOpen,
      canDeleteProjectFolder,
      projectLabels,
      filtedProjects,
    } = this.state;
    console.log(projectLabels);
    console.log(projects);
    console.log(filtedProjects);
    const { hasAccountExpired } = this.props;
    return (
      <section>
        <div className="main-wrapper">
          <Topbar />

          <div className="d-flex new-wrapper sleek-scrollbar">
            <Sidebar activeSideBar="assets" />

            <div className="page-wrapper p-0">
              <div className="page-top-bar">
                <Link to="/" className="d-none d-md-block">
                  Home
                </Link>

                <button
                  className="btn d-md-none p-0 nav"
                  onClick={() => openPageSideBar()}
                >
                  <TextLongIcon />
                </button>

                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="d-flex align-items-center"
                >
                  <label className="search">
                    <SearchIcon />
                    <input
                      onChange={this.handleSearchProjects}
                      placeholder="Search Project"
                    />
                  </label>
                  <button
                    type="reset"
                    onClick={(e) => this.handleSearchProjects()}
                    className="btn p-0 ml-2 md-ml-3"
                  >
                    <InputReload />
                  </button>
                </form>

                <button
                  onClick={() => this.handleModal("add")}
                  disabled={hasAccountExpired}
                  className="btn btn-primary"
                >
                  <PlusIcon />{" "}
                  <span className="d-none d-md-flex">Create Project</span>
                </button>
              </div>
              <div className="content container-fluid">
                <div className="g-crumb">
                  <div className="pageLink">
                    <a href="/">Home</a> <ChevronRightIcon class="active" />{" "}
                    <a href="#" class="active">
                      Assets
                    </a>
                  </div>

                  <select onChange={this.handleFilterByLabel}>
                    <option value="">Select Label</option>
                    {projectLabels && projectLabels.length
                      ? projectLabels.map((label) => (
                          <option key={label} value={label}>
                            {label}
                          </option>
                        ))
                      : null}
                  </select>
                </div>

                {isLoadingProjects ? (
                  <Spinner />
                ) : (
                  <div>
                    <h1 className="page-h1 mb-4">Assets</h1>
                    {filtedProjects && filtedProjects.length ? (
                      <div className="projects-grid-row m-0 w-100">
                        {filtedProjects.map((project) => {
                          let projectTeam = [];
                          let projectLeader = [];
                          if (project.team && project.team.length) {
                            projectTeam = project.team.map((employee) => {
                              return { ...employee, isLeader: false };
                            });
                          }
                          if (
                            project.projectLeader &&
                            project.projectLeader.length
                          ) {
                            projectLeader = project.projectLeader.map(
                              (employee) => {
                                return { ...employee, isLeader: true };
                              }
                            );
                          }
                          return (
                            <Project
                              key={project._id}
                              project={project}
                              projectTeamList={projectTeam}
                              projectLeaderList={projectLeader}
                              onEdit={this.editProject}
                              onDelete={() => this.handleDeleteProject(project)}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <div className="w-100 d-flex flex-column align-items-center m-t-50">
                        <img
                          src="../../assets/svg/undraw_Organizing_projects_0p9a.svg"
                          className="empty-svg"
                        />
                        <p className="mt-4 text-lg">
                          <b>None found</b>
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {isAddModalOpen && (
                <ProjectModal
                  showProjectModal={isAddModalOpen}
                  setShowProjectModal={(isOpen) => this.handleModal("add")}
                  clientList={clientList}
                  teamsICreated={teams}
                  employeesList={employeesList}
                  handleProjectCreated={({ project, folder_id, response }) => {
                    this.updateProjectsList(project);
                    this.handleFileUpload({ folder_id, response, project });
                  }}
                />
              )}

              {isEditModalOpen && (
                <ProjectModal
                  showProjectModal={isEditModalOpen}
                  setShowProjectModal={(isOpen) => this.handleModal("edit")}
                  clientList={clientList}
                  teamsICreated={teams}
                  employeesList={employeesList}
                  mode={"edit"}
                  selectedProject={editProjectData}
                  projectEditStage={1}
                  projectUpdated={({ project, isProjectModalOpen }) =>
                    this.updateProjectsList(project, isProjectModalOpen)
                  }
                />
              )}

              {/*
              <div
                className="modal custom-modal fade"
                id="delete_project"
                role="dialog"
                onClick={(e) => this.resetDeleteModal(e)}
              >
                <div className="modal-dialog modal-dialog-centered modal-sm">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="form-header">
                        <h3>Delete Project</h3>
                        <p>Are you sure want to delete?</p>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center mb-4"
                        style={{ fontSize: "13px", color: "#808080" }}
                      >
                        <input
                          onChange={this.toggleCanDeleteProjectFolder}
                          type="checkbox"
                          checked={canDeleteProjectFolder}
                          className="mr-2"
                        />
                        <span
                          onClick={this.toggleCanDeleteProjectFolder}
                          style={{ cursor: "default" }}
                        >
                          Delete Project Folder
                        </span>
                      </div>
                      <div className="modal-btn delete-action mt-4">
                        <div className="row">
                          <div className="col-6">
                            <span
                              className="btn btn-primary delete-btn"
                              onClick={this.handleDeleteProject}
                            >
                              Delete
                            </span>
                          </div>
                          <div className="col-6">
                            <span
                              data-dismiss="modal"
                              className="btn btn-primary cancel-btn"
                            >
                              Cancel
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              */}

              {/* {this.props.isAddTaskModalOpen && <Modal />} */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAddTaskModalOpen: state.projectReducer.isAddTaskModalOpen,
    token: state.userReducer.token,
    hasAccountExpired: state.userReducer.hasAccountExpired,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUploadFile: (files) => dispatch(setUploadFile(files)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Projects));
