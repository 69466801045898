import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";
import CloseIcon from "mdi-react/CloseIcon";
import PlusIcon from "mdi-react/PlusIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import PaletteIcon from "mdi-react/PaletteIcon";
import ImageOutlineIcon from "mdi-react/ImageOutlineIcon";
import CheckCircleOutlineIcon from "mdi-react/CheckCircleOutlineIcon";
import EditIcon from "mdi-react/EditIcon";
import { URLS } from "../../urls";
import Spinner from "../../global/Spinner";

import { socket } from "../../../sockets/index";

import image1 from "../../../assets/board-default-images/thumbnail/img1.png";
import image2 from "../../../assets/board-default-images/thumbnail/img2.png";
import image3 from "../../../assets/board-default-images/thumbnail/img3.png";
import image4 from "../../../assets/board-default-images/thumbnail/img4.png";
import image5 from "../../../assets/board-default-images/thumbnail/img5.png";
import image6 from "../../../assets/board-default-images/thumbnail/img6.png";
import image7 from "../../../assets/board-default-images/thumbnail/img7.png";
import image8 from "../../../assets/board-default-images/thumbnail/img8.png";
import image9 from "../../../assets/board-default-images/thumbnail/img9.png";
import image10 from "../../../assets/board-default-images/thumbnail/img10.png";
import image11 from "../../../assets/board-default-images/thumbnail/img11.png";
import {
  AboutProjectIcon,
  BoardBackArrowIcon,
  BoardColorCheckedIcon,
  BoardColorPickerIcon,
  BoardImageIcon,
  ColorCheckedIcon,
  NotificationBell,
} from "../../task-update/components/Icons";
import ColorPickerUI from "./CustomColor";
import { debounce, isEmpty } from "lodash";
import {
  allProjectMembers,
  fullName,
  imagePreloader,
  newPhotos,
  newPhotosThumbnail,
  photos,
  showToast,
} from "../../task-update/utils";
import eventBus from "../../../helper/EventBus";
import Avatar from "./../../task-update/components/Avatar";
import { createMarkup } from "../../task-update/utils";
import { formatDistanceToNow } from "date-fns";

const bgImages = [...newPhotos, ...photos];

function Activities({ projectId }) {
  const initialPagination = {
    page: 1,
    limit: 10,
    hasNext: false,
    next: null /* { page: 2, limit: 10 } */,
  };
  const [activities, setActivities] = useState([]);
  const [pagination, setPagination] = useState(initialPagination);

  const getActivities = ({
    page = initialPagination.page,
    limit = initialPagination.limit,
  }) => {
    socket.emit("fx:get-activities", { projectId, page, limit }, (response) => {
      setActivities([...activities, ...response.activities]);
      setPagination(response.pagination);
    });
  };

  useEffect(() => {
    if (projectId) getActivities({});
  }, [projectId]);

  const handleClick = (e) => {
    const userId = e.target.getAttribute("data-user-id");
    const taskId = e.target.getAttribute("data-task-id");

    if (userId) eventBus.dispatch("open-chat", userId);
    if (taskId) eventBus.dispatch("loadTask", { taskId, projectId });

    // set IsClosed
    eventBus.dispatch("setShowBoardSettings", true);
  };

  return !isEmpty(activities) ? (
    <section className="activity border-top mx-4">
      <h2>Activity</h2>
      {activities.map((el, index) => (
        <div onClick={(e) => handleClick(e)} key={index} className="item mb-3">
          <div>
            <Avatar user={el.createdBy} />
          </div>
          <div>
            <p
              className="mb-1"
              dangerouslySetInnerHTML={createMarkup(el.text)}
            ></p>
            <small>
              {formatDistanceToNow(new Date(el.createdAt), {
                addSuffix: true,
              })}
            </small>
          </div>
        </div>
      ))}

      {pagination.hasNext && (
        <div className="d-flex justify-content-center">
          <button
            onClick={() => getActivities(pagination.next)}
            className="btn btn-light btn-sm mb-4 mt-3 p-2 text-center"
          >
            Load more...
          </button>
        </div>
      )}
    </section>
  ) : null;
}

class BoardSettings extends React.PureComponent {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);

    this.state = {
      isClosed: true,
      currentNav: "Board Settings",
      mode: "colors",
      percent_completed: 0,
      loader: false,
      imgLoading: true,
      photos: [
        ...newPhotosThumbnail,
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
        image10,
        image11,
      ],
      colors: [
        "#0ACCA9",
        "#6EC1F9",
        "#FE5B52",
        "#F8A536",
        "#3F96D1",
        "#CBCBFF",
        "#FFBC96",

        "#F62D51",
        "#6f42c1",
        "#e83e8c",
        "#6c757d",
        "#343A40",
      ],
      sortedColorSpec: [],
      childNav: "default",
      childNavImage: "image",
    };
  }

  async componentDidMount() {
    eventBus.on("setShowBoardSettings", (val) => {
      this.setShowBoardSettings(val);
    });
    /*this.setState({
      ...this.state,
      loadingColorCodes: true,
    });
    const colorCodes = this.props.colorCodes;
    if (colorCodes && colorCodes.color_code_desc) {
      // let taskColors = this.state.taskColors;
      let colorSpec = {};

      colorCodes.color_code_desc.map((colorCode) => {
        colorSpec[colorCode.color] = colorCode.description;
      });

      let temp = this.state.taskColors.map((color) => {
        return colorSpec[color] ? { color, desc: colorSpec[color] } : { color };
      });

      temp.sort(function (left, right) {
        return left.hasOwnProperty("desc")
          ? -1
          : right.hasOwnProperty("desc")
          ? 1
          : 0;
      });

      this.setState({
        ...this.state,
        sortedColorSpec: temp,
        loadingColorCodes: false,
      });
    } else {
      let temp = this.state.taskColors.map((color) => {
        return {
          color,
        };
      });
      this.setState({
        ...this.state,
        sortedColorSpec: temp,
        loadingColorCodes: false,
      });
    }
    */
  }

  componentWillUnmount() {
    eventBus.remove("setShowBoardSettings");
  }

  goTo = (current) => {
    this.setState({
      ...this.state,
      currentNav: current,
    });
  };

  goToChild = (current) => {
    this.setState({
      ...this.state,
      childNav: current,
    });
  };

  goToChildImage = (current) => {
    this.setState({
      ...this.state,
      childNavImage: current,
    });
  };

  emitAndSettings = async (settings) => {
    // Download photo first
    if (["photos", "custom-photo"].includes(settings.mode)) {
      showToast({
        message: {
          title: "Downloading image",
        },
        type: "info",
      });
    }
    eventBus.dispatch("showBoardLoader", true);
    if (settings.mode === "photos") {
      await imagePreloader(bgImages[settings.currentPhoto]);
    } else if (settings.mode === "custom-photo") {
      await imagePreloader(
        `${URLS.backendBoardSettings}/custom-photo/${this.props.user._id}?i=${settings.unique}`
      );
    }

    this.props.saveSettings({ ...settings, user_id: this.props.user._id });
    this.saveSettings(settings);
    eventBus.dispatch("showBoardLoader", false);
    if (["photos", "custom-photo"].includes(settings.mode)) {
      showToast({
        message: {
          title: "Image set",
        },
        type: "success",
      });
    }
  };

  upload = (e) => {
    this.setState({
      ...this.state,
      loader: true,
    });
    const file = e.target.files[0];
    let data = new FormData();
    data.append("the_user_id", this.props.user._id);
    data.append("file", file);

    let request = new XMLHttpRequest();
    request.open("POST", `${URLS.backendBoardSettings}/upload-custom-image`);

    request.upload.addEventListener("progress", (e) => {
      const completedPercent = (e.loaded / e.total) * 100;
      this.setState({
        ...this.state,
        percent_completed: completedPercent,
      });
    });

    request.addEventListener("load", (e) => {
      this.setState({
        ...this.state,
        loader: false,
      });
      // console.log(request.status);
      const res = JSON.parse(request.response);
      const { mode, user_id, customPhoto } = res.boardSettings;

      this.emitAndSettings({
        mode,
        user_id,
        customPhoto,
        unique: new Date().getTime(),
      });
    });

    request.send(data);
  };

  handleSaveEditedColorDesc = (e, color) => {
    const description = e.target.innerText.trim();
    const company_id = this.props.user.company_id;

    if (e.key === "Enter" && !e.shiftkey) {
      socket.emit("fx:save-color-code-desc", {
        company_id,
        color,
        description,
      });

      const index = this.state.sortedColorSpec.findIndex(
        (item) => item.color === this.state.currentColor
      );
      const sortedColorSpecClone = this.state.sortedColorSpec;
      sortedColorSpecClone[index].desc = description ? description : null;

      this.setState({
        ...this.state,
        currentColor: null,
        sortedColorSpec: [...sortedColorSpecClone],
      });
    }
  };

  setShowBoardSettings = (value) => {
    this.setState({
      ...this.state,
      isClosed: value,
    });
  };

  setColor = (color) => {
    this.emitAndSettings({ color, mode: "colors" });
  };

  saveSettings = debounce((settings) => {
    socket.emit("fx:save-settings", {
      ...settings,
      user_id: this.props.user._id,
    });
    console.log("saved");
  }, 1000);

  render() {
    const { user, settings, toggleProjectNotification, selectedProject } =
      this.props;
    const { currentNav, colors, photos, childNav, childNavImage, isClosed } =
      this.state;
    const canEdit = user.account_type == 1 || user.isSuperAdmin ? true : false;
    const project = selectedProject;
    const progress = this.props.calculateProjectProgress();
    return (
      <Wrapper
        className={`${
          isClosed ? "sidebar-right-hidden" : "sidebar-right-visible"
        }`}
      >
        <header className="embed-header">
          <div>
            {currentNav !== "Board Settings" ? (
              <BoardBackArrowIcon onClick={() => this.goTo("Board Settings")} />
            ) : null}
            <span className="title-text">{currentNav}</span>
          </div>
          <CloseIcon
            onClick={() => this.setShowBoardSettings(true)}
            className="close-icon p-cursor close-icon-bg"
            title="Close"
          />
        </header>
        <div
          className="w-full scroll-bar overflow-x-hidden"
          style={{ height: "calc(100% - 3rem)" }}
        >
          {currentNav === "Board Settings" && (
            <>
              <div className="notification d-flex justify-content-between w-100 px-4 align-items-center">
                <div>
                  <NotificationBell className="mr-3" />
                  <span>Enable Notification</span>
                </div>

                <label
                  className="switch mt-2"
                  title={
                    selectedProject?.notificationEnabled
                      ? "Disable Project Notifications"
                      : "Enable Project Notifications"
                  }
                >
                  <input
                    type="checkbox"
                    onChange={() => {
                      toggleProjectNotification({
                        projectId: selectedProject._id,
                        enabled: !selectedProject?.notificationEnabled,
                      });
                    }}
                    checked={selectedProject?.notificationEnabled || false}
                  />
                  <span className="slider round"></span>
                </label>
              </div>

              <div className="px-4">
                <button
                  onClick={() => this.goTo("About the Project")}
                  className="btn btn-white w-100 about-project-btn"
                >
                  <AboutProjectIcon />
                  About the project
                </button>
              </div>
              <section className="choose pt-0">
                <p>Change Board Background</p>
                <div className="choice-grid w-100">
                  <div
                    className="p-cursor m-0"
                    onClick={() => this.goTo("Color Background")}
                  >
                    <div className="choice">
                      <span className="icon">
                        <BoardColorPickerIcon />
                      </span>

                      <span>Color</span>
                    </div>
                  </div>

                  <div
                    className="p-cursor m-0"
                    onClick={() => this.goTo("Image Background")}
                  >
                    <div className="choice">
                      <span className="icon">
                        <BoardImageIcon />
                      </span>
                      <span>Image</span>
                    </div>
                  </div>
                </div>

                <button
                  className="btn btn-outline-primary w-100"
                  onClick={() => this.setColor("#E8F0F6")}
                >
                  Set to Default Theme
                </button>
              </section>

              <Activities key={project._id} projectId={project._id} />
            </>
          )}

          {currentNav === "About the Project" && (
            <section className="project-details">
              <div className="mb-4">
                <h2>Team</h2>

                {allProjectMembers(project, {
                  includeInvited: false,
                })
                  ? allProjectMembers(project, {
                      includeInvited: false,
                    }).map((user, index) => (
                      <div key={user._id} className="team">
                        <div className="profile-image">
                          <Avatar width={40} height={40} user={user} />
                        </div>
                        <div>
                          <span className="name">{fullName(user.name)}</span>
                          <span className="username">{`@${user.username}`}</span>
                        </div>
                      </div>
                    ))
                  : null}
              </div>

              <div>
                <h2>Description</h2>
                <p className="desc-text">{project.description || "..."}</p>
              </div>

              <div className="card">
                <div
                  className="card-body border rounded p-3"
                  //style={{ padding: "0.8rem" }}
                >
                  <h2 className="card-title m-b-15">Project details</h2>
                  <table className="table table-borderless project-details-table">
                    <tbody>
                      {/* clientInfo && (
                        <tr>
                          <td>Client:</td>
                          <td className="text-right">
                            {clientInfo.companyName || "..."}
                          </td>
                        </tr>
                      ) */}
                      <tr>
                        <td>Cost:</td>
                        <td className="text-right">{project.cost || "..."}</td>
                      </tr>
                      <tr>
                        <td>Duration:</td>
                        <td className="text-right">
                          {(project.duration || "...") +
                            " " +
                            (project.durationType || "...")}
                        </td>
                      </tr>
                      <tr>
                        <td>Start date:</td>
                        <td className="text-right">
                          {project.startDate || "..."}
                        </td>
                      </tr>
                      <tr>
                        <td>Deadline:</td>
                        <td className="text-right">
                          {project.endDate || "..."}
                        </td>
                      </tr>
                      <tr>
                        <td>Priority:</td>
                        <td className="text-right">
                          <div className="btn-group">
                            {project.priority === "High" ? (
                              <a>
                                <i className="fa fa-dot-circle-o text-danger"></i>{" "}
                                High
                              </a>
                            ) : project.priority === "Medium" ? (
                              <a>
                                <i className="fa fa-dot-circle-o text-primary"></i>{" "}
                                Normal
                              </a>
                            ) : (
                              <a>
                                <i className="fa fa-dot-circle-o text-success"></i>{" "}
                                Low{" "}
                              </a>
                            )}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Created by:</td>
                        <td className="text-right">
                          <a>
                            {project.createdBy
                              ? fullName(project.createdBy.name)
                              : null}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td>Status:</td>
                        <td className="text-right">{project.status}</td>
                      </tr>
                    </tbody>
                  </table>

                  <p className="m-b-5">
                    <span className="text-dark">Progress</span>{" "}
                    <span className="text-success float-right">
                      {progress + "%"}
                    </span>
                  </p>
                  <div className="progress progress-xs mb-0">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      data-toggle="tooltip"
                      title={progress.toString() + "%"}
                      style={{ width: progress.toString() + "%" }}
                    ></div>
                  </div>
                </div>
              </div>
            </section>
          )}

          <section
            style={
              currentNav === "Color Background"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div className="d-flex nav mb-3">
              <button
                className={`btn w-50 ${childNav === "default" && "active"} `}
                onClick={() => this.goToChild("default")}
              >
                Default
              </button>
              <button
                className={`btn w-50 ${childNav === "custom" && "active"} `}
                onClick={() => this.goToChild("custom")}
              >
                Custom
              </button>
            </div>
            {childNav === "default" ? (
              <div className="colors pt-0">
                {colors.map((color, i) => {
                  return (
                    <div
                      key={i}
                      className={`card p-cursor`}
                      style={{ background: color }}
                      onClick={() =>
                        this.emitAndSettings({ color, mode: "colors" })
                      }
                    >
                      <BoardColorCheckedIcon
                        className={`check-icon ${
                          settings.mode === "colors" && settings.color === color
                            ? ""
                            : "d-none"
                        }`}
                      />
                      <div className="card-body"></div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <ColorPickerUI color={settings.color} setColor={this.setColor} />
            )}
          </section>

          <section
            style={
              currentNav === "Image Background"
                ? { display: "block" }
                : { display: "none" }
            }
          >
            <div>
              <p className="photos-title d-none">Choose an image below</p>
              <div className="d-flex nav mb-3">
                <button
                  className={`btn w-50 ${
                    childNavImage === "image" && "active"
                  } `}
                  onClick={() => this.goToChildImage("image")}
                >
                  Images
                </button>
                <button
                  className={`btn w-50 ${
                    childNavImage === "pattern" && "active"
                  } `}
                  onClick={() => this.goToChildImage("pattern")}
                >
                  Patterns
                </button>
              </div>
              <div className="photos">
                <div
                  className={`card p-cursor1 ${
                    !settings.customPhoto ? "d-none" : ""
                  }`}
                  onClick={() =>
                    this.emitAndSettings({
                      mode: "custom-photo",
                      user_id: user._id,
                      customPhoto: settings.customPhoto,
                      unique: new Date().getTime(),
                    })
                  }
                >
                  <div className="card-body p-0 m-0">
                    <BoardColorCheckedIcon
                      className={`check-icon  ${
                        settings.mode === "custom-photo" ? "" : "d-none"
                      }`}
                    />
                    <img
                      src={
                        user._id &&
                        `${URLS.backendBoardSettings}/custom-photo/thumbnail/${user._id}?i=${settings.unique}`
                      }
                      alt="preset-img"
                      className=" w-100"
                    />
                  </div>
                </div>
                {photos.slice(0, 13).map((photo, i) => {
                  return (
                    <div
                      key={i}
                      style={
                        childNavImage === "image"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                      className="card p-cursor"
                      onClick={() =>
                        this.emitAndSettings({
                          currentPhoto: i,
                          mode: "photos",
                        })
                      }
                    >
                      <div className="card-body p-0 m-0">
                        <BoardColorCheckedIcon
                          className={`check-icon ${
                            settings.mode === "photos" &&
                            settings.currentPhoto == i
                              ? ""
                              : "d-none"
                          } ${i === 9 ? "text-dark" : ""}`}
                        />
                        {
                          <img
                            src={photo}
                            data-src={`https://source.unsplash.com/random?q=${photo}`}
                            alt="preset-img"
                            className="img-fluid"
                          />
                        }
                      </div>
                    </div>
                  );
                })}

                {photos.slice(13, 23).map((photo, i) => {
                  return (
                    <div
                      key={i}
                      style={
                        childNavImage === "pattern"
                          ? { display: "block" }
                          : { display: "none" }
                      }
                      className="card p-cursor"
                      onClick={() =>
                        this.emitAndSettings({
                          currentPhoto: i + 13,
                          mode: "photos",
                        })
                      }
                    >
                      <div className="card-body p-0 m-0">
                        <BoardColorCheckedIcon
                          className={`check-icon ${
                            settings.mode === "photos" &&
                            settings.currentPhoto == i + 13
                              ? ""
                              : "d-none"
                          } ${i === 9 ? "text-dark" : ""}`}
                        />
                        <img
                          src={photo}
                          data-src={`https://source.unsplash.com/random?q=${photo}`}
                          alt="preset-img"
                          className=" w-100"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <label className="w-100 p-3 mt-2 p-cursor">
                <div
                  className="bg-primary p-1 p-cursor"
                  style={
                    !this.state.loader
                      ? {
                          display: "none",
                        }
                      : {
                          display: "block",
                          width: `${this.state.percent_completed}%`,
                        }
                  }
                ></div>
                <div className="add-new p-cursor">
                  <PlusIcon />
                  <span className="p-cursor">Upload Photo</span>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  id="upload"
                  onChange={(e) => this.upload(e)}
                  className="d-none"
                />
              </label>
            </div>
          </section>
        </div>
      </Wrapper>
    );
  }
}

export default BoardSettings;

const Wrapper = styled.div`
  width: 24.4375rem;
  height: calc(var(--vh, 1vh) * 100);
  bottom: 0;
  right: 0;
  position: fixed;
  box-shadow: -10px 4px 13px 0px #d1dbe438;
  z-index: 1060;
  background-color: #fff;
  transition: transform 300ms ease-in;

  .notification {
    margin-bottom: 1.375rem;
    font-size: 1rem;
    font-weight: 500;
    // height: 1.265rem;
  }

  .about-project-btn {
    border: 1px solid #ced8e1;
    height: 3.25rem;
    color: #4b5458;
    font-weight: 600;
    font-size: 0.875rem;
    margin-bottom: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
  }

  .color-code * {
    font-size: 13px;
  }

  .color-desc .color-code-icon {
    display: none;
  }

  .color-desc:hover .color-code-icon {
    display: block;
  }

  .color-desc:hover .color-code-icon {
  }

  .color-circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin: 5px;
    cursor: pointer;
  }

  .card {
    border: none;
    height: fit-content;
  }

  .sidebar-right-visible {
    transform: translateX(0%);
    z-index: 1000;
  }

  .sidebar-right-hidden {
    transform: translateX(120%);
  }

  .colors .card,
  .photos .card {
    border-radius: 0.3rem;
    height: 6.875rem !important;
    margin: 0;
    overflow: hidden;

    .card-body {
      height: inherit;
    }
  }

  .photos .card {
    width: calc(24.4375rem / 2 - 2rem);

    img {
      overflow: hidden;
      height: 100%;
      width: 100%;
      border-radius: 0.3rem;
    }
  }

  .colors,
  .photos {
    display: grid;
    grid-template-columns: auto auto;
    // justify-content: space-between;
    padding: 1rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    grid-gap: 1rem 1rem;
    margin-bottom: 2rem;
  }

  .colors {
    padding: 1rem;
  }

  .photos-title {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin: 0;
    font-weight: 500;
  }

  .card:hover {
    // border: 1px solid #2569A1;
    color: #2569a1;
  }

  .colors .card:hover {
    // border: 1px solid #9E9E9E;
  }

  .photos .card:hover {
    // opacity: 0.8;
  }

  .add-new {
    margin-bottom: 30px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #ccc;
    border-radius: 3px;
    height: 150px;
    cursor: default;
  }

  .color-box {
    height: 5rem;
  }

  .embed-header {
    // height: 3rem;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #111111;
    padding: 1.8rem 1.5rem 1.8rem 1.5rem;

    > div {
      display: flex;
      align-items: center;
    }

    .title-text {
      font-weight: bold;
      font-size: 15px;
      line-height: 0;
    }

    .close-icon {
      height: 2rem;
      width: 2rem;
      margin: 0;
    }

    > span:nth-child(2) {
      text-transform: capitalize;
      font-size: 13px;
      padding: 0 0.5rem;
    }
  }
  .w-full {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .h-full {
    height: 100%;
  }
  .p-cursor {
    cursor: pointer;
  }

  .close-icon-bg {
    background-color: #f2f7fc;
    padding: 5px;
    border-radius: 50%;
  }

  .nav {
    position: sticky;
    z-index: 5;
    background: #ffffff;
    top: 0;

    .btn {
      border-radius: 0 !important;
      font-weight: 600;
      color: #677888;
      border-bottom: 3px solid #ffffff;
    }
    .btn.active {
      border-bottom: 3px solid #102e51;
      color: #102e51;
    }
  }

  .choose {
    padding: 1.5rem;
    padding-right: 1.5rem;

    .choice-grid {
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 0.8125rem;
    }

    p {
      color: #5d696f;
      font-weight: 500;
    }

    .choice {
      height: 7.875rem;
      border-radius: 0.375rem;
      display: flex;
      flex-direction: column;
      font-size: 1rem;
      font-weight: 600;
      align-items: center;
      justify-content: center;

      span:nth-of-type(2) {
        margin-top: 1rem;
        line-height: 140%;
      }

      &:hover {
      }

      .icon {
        width: 4rem;
        height: 4rem;
        background: #e8f0f6;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &:hover {
        }
      }
    }

    .btn {
      padding-top: 0.875rem;
      padding-bottom: 0.875rem;
      font-size: 0.875rem !important;
      margin-top: 1rem;
      line-height: 140%;
      border-radius: 0.5rem;
    }
  }

  .activity {
    padding: 1rem 0.5rem 2rem 0.5rem;
    font-size: 0.875rem;

    h2 {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }

    .item {
      display: flex;
      gap: 0.75rem;

      small {
        color: #677888;
        font-size: 0.75rem;
      }

      [data-user-id] {
        font-weight: 600;
        cursor: pointer;
      }

      [data-task-id] {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .project-details {
    padding: 1.5rem;
    padding-top: 0;

    h2 {
      font-size: 1rem;
      color: #4b5458;
      margin-bottom: 1rem;
    }

    .team {
      display: flex;
      margin-bottom: 1rem;
      gap: 1rem;

      .profile-image {
        position: relative;
      }

      span {
        display: block;
        font-size: 0.75rem;

        &.name {
          font-weight: 600;
        }
        &.username {
          color: #677888;
        }
      }
    }

    .desc-text {
      min-height: 7.25rem;
      border: 1px solid #ced8e1;
      background: #f3f5f7;
      padding: 0.75rem;
      border-radius: 0.5rem;
      font-size: 0.75rem;
      margin-bottom: 1.5rem;
      white-space: pre-line;
    }

    table {
      font-size: 0.75rem;
    }

    .text-dark {
      font-size: 0.875rem;
      font-weight: 600;
      color: #4b5458;
    }
  }

  .check-icon {
    position: absolute;
    top: 40%;
    left: 42%;
  }

  @media only screen and (max-width: 960px) {
    width: 100vw;

    .photos .card {
      width: calc(50vw - 2rem);
    }
  }
`;
