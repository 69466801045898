import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import asyncComponent from "./components/AsyncComponent";

import styled from "styled-components";
import Cookies from "universal-cookie";
import { URLS } from "./components/urls.js";

import AsyncLandingPage from "./components/landingPage/LandingPage.js";
import Dashboard from "./components/dashboard/Dashboard.js";
import Tasks from "./components/tasks/Tasks";
import SingleProjectTasks from "./components/tasks/SingleProjectTasks";
import Projects from "./components/projects/Projects";
import assets from "./components/projects/assets";
import Teams from "./components/teams/Teams";
import ProjectView from "./components/project-view/ProjectView.js";
import TeamView from "./components/team-view/TeamView.js";
import MemberView from "./components/member-view/MemberView.js";
import Performance from "./components/performance/Performance";
import Clients from "./components/clients/Clients.js";
import Profile from "./components/profile/Profile";
import Error404 from "./components/error404/Error404";
import ClientProfile from "./components/client-profile/ClientProfile.js";
import RedirectComponent from "./components/redirect/RedirectComponent";
import Addons from "./components/global/Addons";
import Subscription from "./components/global/Subscription";
import Loader from "./components/global/Loader";
import Toast from "./components/global/Toast";
import Invite from "./components/global/Invite";
import {
  toggleRightSideBar,
  toggleProfileInfo,
  toggleInviteModal,
  toggleToastMessage,
  toggleErp,
} from "./store/actions/topBarActions";
import {
  populateUser,
  populateNotifications,
  setToken,
  updateAccountExpiration,
  updateFreeUser,
} from "./store/actions/userActions";

import { verifyToken } from "./auth/authHelper";
import { cloudSignIn } from "./services/authService";
import { socket } from "./sockets";

import JobView from "./components/job-view/JobView";
import OnBoard from "./components/topbar/Onboard";
import Board from "./components/task-update/Board";
import CRM from "./components/crm/Board";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginModal from "./LoginModal";
import projectPerformance from "./components/performance/projectPerformance";
import OfflineCheck from "./OfflineCheck";

const cookies = new Cookies();

const environment = process.env.REACT_APP_ENV;

const CLOUD_DRIVE_FRONTEND = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;

/*const redirectUrl =
  CLOUD_DRIVE_FRONTEND +
  "/login?redirect=" +
  process.env.REACT_APP_FRONTEND_URL;*/

const anonymousPaths = ["/project-invite", "/task-invite"];
const getRedirectUrl = () => {
  return `${CLOUD_DRIVE_FRONTEND}/login?redirect=${window.location.href}`;
};

const PropsRoute = ({ component: Component, path: path, ...rest }) => {
  const token = cookies.get("token");
  const isValidToken = verifyToken(token);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        const pathString = `/${window.location.pathname.split("/")[1]}`;
        if (environment === "development") {
          if (
            !isValidToken &&
            path != "/login" &&
            !anonymousPaths.includes(pathString)
          ) {
            // alert(getRedirectUrl());
            return <Redirect to="/login" />;
          }
          return <Component {...routeProps} />;
        } else {
          if (!isValidToken && !anonymousPaths.includes(pathString)) {
            return <Loader />;
          } else {
            return <Component {...routeProps} />;
          }
        }
      }}
    />
  );
};

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      environment: process.env.REACT_APP_ENV,
      user: props.user,
      email: "",
      password: "",
      buttonText: "Login",
      errorMessage: "",
      hasTokenExpired: false,

      introJsOptions: {
        showBullets: false,
        hidePrev: true,
        scrollToElement: false,
        // showStepNumbers: true,
        skipLabel: "Skip",
        doneLabel: "Got it",
        nextLabel: "Take tour",
        steps: [
          {
            title: "Welcome",
            intro:
              "Welcome to Fexspace, your new workspace where your important collaboration tools are organized in one place",
          },
        ],
      },
      // isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
    const { hasTokenExpired } = this.state;

    const token = cookies.get("token");
    const isValidToken = verifyToken(token);
    const pathString = `/${window.location.pathname.split("/")[1]}`;
    if (isValidToken) {
      fetch(`${URLS.backendUsers}/validateUser`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            if (result.user && Object.entries(result.user).length) {
              this.props.populateUser(result.user, true);
              this.setState({ isLoading: false });
            }
          } else {
            if (anonymousPaths.includes(pathString)) {
              this.setState({ isLoading: false });
              return;
            }
            if (environment !== "development" && !hasTokenExpired) {
              window.location.replace(getRedirectUrl());
            } else {
              window.location.assign("/login");
            }
          }
        });
    } else {
      if (anonymousPaths.includes(pathString)) {
        this.setState({ isLoading: false });
        return;
      }
    }

    //Check token validity every 5secs
    this.checkLoginExpiration();
  }

  closeRightSideBar = () => {
    this.props.toggleRightSideBar();
  };

  updateUserEmail = (event) => {
    const email = event.target.value;
    this.setState({
      email,
      errorMessage: "",
    });
  };

  updateUserPassword = (event) => {
    const password = event.target.value;
    this.setState({
      password,
      errorMessage: "",
    });
  };

  handleError = () => {
    this.setState({
      buttonText: "Login",
      errorMessage: "Invalid Login Credentials",
    });
  };

  signIn = (e) => {
    e && e.preventDefault && e.preventDefault();
    const { email, password } = this.state;
    const user = this.props.user;

    if (!email.trim() || !password.trim()) {
      this.setState({ errorMessage: "Please fill all fields" });
    } else {
      this.setState({ buttonText: "Signing in...", isWaiting: true });
      cloudSignIn({ email, password })
        .then(async (response) => {
          if (response.data) {
            const newEmail = response.email;
            const userEmail = user.email;

            if (userEmail == newEmail) {
              const token = cookies.get("token");
              await this.props.setToken(token);
              this.setState({
                token,
                hasTokenExpired: false,
                email: "",
                password: "",
                buttonText: "",
              });
            } else {
              this.handleError();
            }
          } else {
            this.handleError();
          }
        })
        .catch((err) => {
          this.handleError();
        });
    }
  };

  handleLoginExpiration = () => {
    try {
      const token = cookies.get("token");
      socket.emit("fx:check-token", token, (response) => {
        if (
          response &&
          response.sessionStatus &&
          response.sessionStatus === "expired"
        ) {
          //Show re-login modal
          // this.setState({ hasTokenExpired: true });
          //Redirect to login page
          window.location.replace(getRedirectUrl());
        }
        // else {
        // 	this.setState({ hasTokenExpired: false });
        // 	this.props.setToken(token);
        // }
        // Handle account expiration
        let currentHasAccountExpired = this.props.hasAccountExpired;
        let incomingHasAccountExpired = response.hasAccountExpired;

        if (incomingHasAccountExpired !== currentHasAccountExpired) {
          this.props.updateAccountExpiration(incomingHasAccountExpired);
        }

        if (response.isFreeUser) {
          // window.location.replace(CLOUD_DRIVE_FRONTEND);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  checkLoginExpiration = () => {
    this.handleLoginExpiration();
    setInterval(() => {
      this.handleLoginExpiration();
    }, 5000);
  };

  handleHideModals = (e) => {
    const canCloseProfileInfo = e.target.closest(".data-profile")
      ? true
      : false;
    if (!canCloseProfileInfo) {
      this.props.toggleProfileInfo(false);
    }
    const canCloseErpInfo = e.target.closest(".data-erp") ? true : false;
    if (!canCloseErpInfo) {
      this.props.toggleErp(false);
    }
  };

  handleInviteModal = (param) => {
    if (Object.entries(param) && param.hasSentInvite) {
      this.props.toggleInviteModal(!param.hasSentInvite);
      this.props.toggleToastMessage(param.message);
    } else {
      this.props.toggleInviteModal(param);
    }
  };

  render() {
    const {
      email,
      password,
      buttonText,
      errorMessage,
      hasTokenExpired,
      isLoading,
      introJsOptions,
    } = this.state;
    const {
      type,
      hasAccountExpired,
      toastMessage,
      isInviteModalVisible,
      user,
      showLoginModal,
    } = this.props;

    const token = cookies.get("token");
    const isValidToken = verifyToken(token);
    const pathString = `/${window.location.pathname.split("/")[1]}`;

    if (!isValidToken && !anonymousPaths.includes(pathString)) {
      if (environment !== "development" && !hasTokenExpired) {
        window.location.replace(getRedirectUrl());
      }
    }

    return isLoading ? (
      <Loader />
    ) : (
      <>
        {user.name && <OnBoard user={user} />}
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick={true}
          pauseOnHover={true}
          draggable={true}
          progress={undefined}
          closeButton={true}
        />
        <OfflineCheck />
        <Wrapper>
          <div onClick={this.handleHideModals}>
            <Router>
              <Switch>
                <Route exact path={URLS.login} component={AsyncLandingPage} />
                <PropsRoute exact path={URLS.reports} component={Dashboard} />
                <PropsRoute exact path="/" component={Dashboard} />
                <PropsRoute exact path={URLS.clients} component={Clients} />
                <PropsRoute exact path={URLS.projects} component={Projects} />
                <PropsRoute exact path={URLS.assets} component={assets} />
                <PropsRoute exact path={URLS.teams} component={Teams} />
                <PropsRoute exact path={URLS.tasks} component={Board} />
                <PropsRoute exact path={URLS.board} component={Board} />
                <PropsRoute exact path={URLS.crm} component={CRM} />
                <PropsRoute path={URLS.taskView} component={Board} />
                <PropsRoute path={URLS.createTask} component={Board} />
                {/*<PropsRoute path={URLS.tasksBoard} component={Board} /> */}
                <PropsRoute
                  exact
                  path={URLS.performance}
                  component={Performance}
                />
                <PropsRoute path={URLS.profile} component={Profile} />
                <PropsRoute
                  path={URLS.redirect}
                  component={RedirectComponent}
                />
                <PropsRoute
                  path={URLS.clientProfile}
                  component={ClientProfile}
                />
                <PropsRoute path={URLS.projectView} component={ProjectView} />
                <PropsRoute
                  path={URLS.projectPerformance}
                  component={projectPerformance}
                />
                <PropsRoute path={URLS.teamView} component={TeamView} />
                <PropsRoute path={URLS.memberView} component={MemberView} />

                <PropsRoute path={URLS.SingleProjectTasks} component={Board} />
                <PropsRoute path={URLS.projectInvite} component={Board} />
                <PropsRoute path={URLS.taskInvite} component={Board} />
                <Route path="*" component={Error404} />
              </Switch>
            </Router>
            <Addons
              type={type}
              className={`${
                this.props.isRightSidebarVisible
                  ? "sidebar-right-visible"
                  : "sidebar-right-hidden"
              }`}
              onClose={() => this.closeRightSideBar()}
              notificationIncrement={(num, type) =>
                this.props.populateNotifications(num, type)
              }
            />
            {hasAccountExpired ? (
              <Subscription message="You don't have an active subscription!" />
            ) : null}
            {toastMessage ? <Toast message={toastMessage} /> : null}
            {isInviteModalVisible ? (
              <Invite
                toggleInviteModal={(value) => this.handleInviteModal(value)}
                token={token}
                user={user}
              />
            ) : null}

            {showLoginModal && <LoginModal signIn={this.signIn} />}

            {/* {hasTokenExpired ? (
						<div className={`session-modal ${hasTokenExpired ? 'show-session-modal' : null}`}>
							<div className="session-modal-content">
								<div className="dv-sd">
									<img
										src={URLS.landingPage + '/assets/img/logo-blue.png'}
										alt="Fexspace Logo"
										width="140"
										height="40"
										className="lg-img"
									/>
									<h1 className="s-continue">Sign in to continue</h1>
									<p className="y-session">Your login session has expired</p>
									{errorMessage ? <p style={{ color: 'red' }}>{errorMessage}</p> : null}
								</div>
								<form className="fm-session" onSubmit={(e) => this.signIn(e)}>
									<div className="form-group">
										<label>Email Address</label>
										<input
											className="form-control"
											type="text"
											value={email}
											onChange={this.updateUserEmail}
											style={{ width: '100%', height: '40px' }}
											placeholder="Email"
										/>
									</div>
									<div className="form-group">
										<div className="row">
											<div className="col">
												<label>Password</label>
											</div>
										</div>
										<input
											className="form-control"
											type="password"
											value={password}
											onChange={this.updateUserPassword}
											style={{ width: '100%', height: '40px' }}
											placeholder="Password"
										/>
									</div>
									<button className="btn login-button" type="submit">
										{buttonText}
									</button>
								</form>
							</div>
						</div>
					) : null} */}
          </div>
        </Wrapper>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    showLoginModal: state.userReducer.showLoginModal,
    isRightSidebarVisible: state.topBarReducer.isRightSidebarVisible,
    type: state.topBarReducer.type,
    user: state.userReducer.user,
    hasAccountExpired: state.userReducer.hasAccountExpired,
    isInviteModalVisible: state.topBarReducer.isInviteModalVisible,
    toastMessage: state.topBarReducer.toastMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleRightSideBar: () => dispatch(toggleRightSideBar()),
    populateUser: (user, shouldUpdateToken) =>
      dispatch(populateUser(user, shouldUpdateToken)),
    populateNotifications: (num, type, description) =>
      dispatch(populateNotifications(num, type, description)),
    setToken: (token) => dispatch(setToken(token)),
    toggleProfileInfo: (value) => dispatch(toggleProfileInfo(value)),
    toggleErp: (value) => dispatch(toggleErp(value)),
    toggleInviteModal: (value) => dispatch(toggleInviteModal(value)),
    toggleToastMessage: (value) => dispatch(toggleToastMessage(value)),
    updateAccountExpiration: (value) =>
      dispatch(updateAccountExpiration(value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);

const Wrapper = styled.div`
  .sidebar-right-visible {
    transform: translateX(0%);
  }

  .sidebar-right-hidden {
    transform: translateX(120%);
  }

  .session-modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1000000; /* Sit on top */
    padding-top: 8%; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }

  .session-modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 20px 40px 40px;
    border: 1px solid #888;
    width: 35%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
  }

  .dv-sd {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }

  .lg-img {
    object-fit: contain;
    margin-top: 10px;
  }

  .s-continue {
    font-size: 16px;
    text-align: center;
    margin-top: 0;
    color: #2c3e55;
    font-weight: bold;
    margin-bottom: 0 !important;
  }

  .y-session {
    font-size: 15px;
    text-align: center;
    margin-top: 5px;
  }

  .fm-session {
    label {
      display: block;
      text-align: center;
      color: #2c3e55;
      font-weight: bold;
      font-size: 16px;
    }

    input {
      text-align: center;
      background-color: #f8f8f8;
    }
  }

  @-webkit-keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }

  .session-modal-header {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }

  .session-modal-body {
    padding: 2px 16px;
  }

  .session-modal-footer {
    padding: 2px 16px;
    background-color: #5cb85c;
    color: white;
  }

  .login-button {
    background-color: #1e6abb;
    color: #fff;
    width: 100%;
    height: 50px !important;
    margin-top: 7px;
    cursor: default;
    border-radius: 3px !important;
  }

  .show-session-modal {
    display: block;
  }

  @media only screen and (max-width: 960px) {
    .sidebar-right-visible {
      z-index: 10010;
    }
  }
`;
