import React, { useRef, useState, useEffect, useMemo } from "react";
import "../../../assets/scss/mention.scss";
import { SearchIcon } from "./Icons";
import Avatar from "./Avatar";
import { cloneDeep, isEmpty } from "lodash";
import { Form } from "react-bootstrap";
import { useOnClickOutside } from "../custom-hooks";
import { fullName } from "../utils";
import eventBus from "../../../helper/EventBus";

export default function Mention({
  usersList,
  style = {},
  listStyle = {},
  hideTitle,
  handleSelectUser,
  inputFocus = true,
  setShowUsersList,
  fowardedMentionRef = {},
  popoverProps = {},
  hideSearchInput = false,
}) {
  let usersListRef = useRef("");
  let localRef = useRef("");
  const initialUsers = {
    leaders: [],
    team: [],
    assignedTo: [],
    ogLeaders: [],
    ogTeam: [],
    ogAssignedTo: [],
  };
  const [searchString, setSearchString] = useState("");
  const [users, setUsers] = useState({ ...initialUsers });

  useOnClickOutside(localRef, () => {
    if (localRef.current && setShowUsersList) setShowUsersList(false);
  });

  useEffect(() => {
    // console.log("mention");
    return () => {};
  }, []);

  useEffect(() => {
    if (usersList && Object.keys(usersList).length) {
      setUsers({
        leaders: [...cloneDeep(usersList.leaders ? usersList.leaders : [])],
        team: [...cloneDeep(usersList.team ? usersList.team : [])],
        assignedTo: [
          ...cloneDeep(usersList.assignedTo ? usersList.assignedTo : []),
        ],
        ogLeaders: [...cloneDeep(usersList.leaders ? usersList.leaders : [])],
        ogTeam: [...cloneDeep(usersList.team ? usersList.team : [])],
        ogAssignedTo: [
          ...cloneDeep(usersList.assignedTo ? usersList.assignedTo : []),
        ],
      });
      if (usersListRef) usersListRef.current.scrollTop = 0;
    } else {
      setUsers({
        ...initialUsers,
      });
    }
    return () => {};
  }, [usersList]);

  const handleFilterByName = (arr, value) => {
    let result = [];
    result = arr.filter((member) => {
      let name = fullName(member.name);
      if (name.toLowerCase().includes(value)) {
        return member;
      }
    });
    return result;
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const searchParams = e.target.value;
    searchUsers(searchParams);
  };

  const searchUsers = (searchParams) => {
    searchParams = searchParams.trim();
    searchParams = searchParams.toLowerCase();
    setSearchString(searchParams);
    const leaders = handleFilterByName(users.ogLeaders, searchParams);
    const team = handleFilterByName(users.ogTeam, searchParams);
    const assignedTo = handleFilterByName(users.ogAssignedTo, searchParams);
    setUsers({ ...users, leaders, team, assignedTo });
  };

  eventBus.useCustomEventListener("onSearchUsersInMention", searchUsers);

  return (
    <div ref={localRef}>
      <div
        id="mention"
        style={{
          ...style,
          ...(hideSearchInput &&
          isEmpty(users.leaders) &&
          isEmpty(users.team) &&
          isEmpty(users.assignedTo)
            ? { display: "none" }
            : {}),
        }}
        ref={(el) => (fowardedMentionRef.current = el)}
      >
        {!hideSearchInput && (
          <div className="search-bar mb-3">
            <input
              type="text"
              placeholder="Search..."
              name="search"
              className="w-75"
              autoFocus={inputFocus}
              value={searchString}
              onChange={(e) => handleSearch(e)}
              autoComplete="off"
            />

            <SearchIcon />
          </div>
        )}

        <div className="users-list mr-2" ref={usersListRef} style={listStyle}>
          {users.leaders?.length ? (
            <>
              {/*!hideTitle && <p>Lead</p>*/}
              {users.leaders.map((user, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (handleSelectUser) {
                      handleSelectUser(user);
                    }
                  }}
                  className="user-holder d-flex p-cursor"
                >
                  <div className="image mr-3">
                    <Avatar
                      user={{ ...user, isLeader: true }}
                      badgeStyle={{
                        bottom: "0px",
                        right: "-0.2rem",
                      }}
                    />
                  </div>
                  <div className="user-details">
                    <span className="name">{fullName(user.name)}</span>
                    <span className="user-name">
                      {user.username ? `@${user.username}` : ""}
                    </span>
                  </div>
                </div>
              ))}
            </>
          ) : null}

          {users.team?.length ? (
            <>
              {/*!hideTitle && <p>Team</p>*/}
              {users.team.map((user, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (handleSelectUser) handleSelectUser(user);
                  }}
                  className="user-holder d-flex"
                >
                  <div className="image mr-3">
                    <Avatar user={user} />
                  </div>
                  <div className="user-details">
                    <span className="name">{fullName(user.name)}</span>
                    <span className="user-name">
                      {user.username ? `@${user.username}` : ""}
                    </span>
                  </div>
                </div>
              ))}
            </>
          ) : null}

          {users.assignedTo?.length ? (
            <>
              {!hideTitle && <p>Assigned To</p>}
              {users.assignedTo.map((user, index) => (
                <div
                  key={index}
                  onClick={() => {
                    if (handleSelectUser) handleSelectUser(user);
                  }}
                  className="user-holder d-flex align-items-center"
                >
                  <div className="image mr-3">
                    <Avatar user={user} />
                  </div>
                  <div className="user-details">
                    <span className="name">{fullName(user.name)}</span>
                    <span className="user-name">
                      {user.username ? `@${user.username}` : ""}
                    </span>
                  </div>
                </div>
              ))}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
