import CloseIcon from "mdi-react/CloseIcon";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Modal, Dropdown, Form, Table } from "react-bootstrap";
import Datepicker from "react-datetime";
import {
  CheckmarkIcon,
  CalendarIcon,
  Line,
  UncheckedboxIcon,
  SearchIcon,
  CheckboxIcon,
  ColorCheckedIcon,
  ChevronDownIcon,
  CheckmarkGreyIcon,
} from "./../components/Icons";

import "../../../assets/scss/customerModal.scss";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { searchUserByName } from "../../../services/userServices";
import {
  addCustomer,
  updateCustomer,
  getCustomerById,
} from "../../../services/customerServices";
import { debounce, validateEmail } from "../../../utils";
import {
  allTaskMembers,
  getUniqueListBy,
  showToast,
  validationStartDate,
} from "../utils";
import ColorPicker from "../components/ColorPicker";
import Avatar from "../components/Avatar";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import eventBus from "../../../helper/EventBus";
import { cloneDeep, uniq } from "lodash";
import { getTasksByTeamId } from "../../../services/teamServices";
import { compareAsc } from "date-fns";
import { getProjectById } from "../../../services/projectServices";

import Spinner from "./../../global/Spinner";
import { TodoList } from "../components/TodoList";
import DialogModal from "./DialogModal";

function AssignedTo({ assignedTo, data }) {
  return (
    <div className="ml-2 assigned-users">
      <>
        {assignedTo
          ? assignedTo.map((user, index) => {
              return (
                <Avatar
                  key={index}
                  width={32}
                  height={32}
                  user={
                    user.name
                      ? user
                      : data.ogTeamFixed.find((el) => el._id === user._id)
                  }
                  zIndex={10 - index}
                  displayBadge={false}
                />
              );
            })
          : "..."}
      </>
    </div>
  );
}

function CustomerModal({
  user,
  showTaskModal,
  setShowTaskModal,
  selectedProject,
  selectedList,
  token,
  taskCreated,
  mode,
  selectedTask,
  taskUpdated,
  taskEditStage,
  setTaskEditStage,
}) {
  // const [showTaskModal, setShowTaskModal] = useState(false);
  const [isLoadingLatest, setIsLoadingLatest] = useState(false);
  const [searchParams, setSearchParams] = useState("");
  const [creationStage, setCreationStage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({
    employeesList: [],
    canAddToCalendar: false,
    team: [],
    ogTeam: [],
    newProjectMembers: [],
    newTaskMembers: [],
    ogAssignedTo: [],
    membersToRemove: [],
    emailsToInvite: [],
    taskEmails: [],
  });
  const [task, setTask] = useState({
    taskName: "",
    taskDetail: null,
    taskDesc: "",
    taskWebsiteAddress: "",
    startDate: moment(new Date()).format("DD/MM/YYYY"),
    dueDate: "",
    customerType: selectedTask?.customer_type,
    aboutUs: selectedTask?.how_did_you_know_about_us,
    productInterest: selectedTask?.product_of_interest,
    status: selectedList?.status,
    project: selectedProject?._id,
    assignedTo: [],
    color: "",
    membersToJoin: [],
    name: { first: "", last: "" },
    email: "",
    phone: "",
    position: "",
  });

  const [contactPersons, setContactPersons] = useState([]);

  const [populatedColors, setPopulatedColors] = useState([
    "#DCECF7",
    "#CAFFDF",
    "#FFF4D8",
  ]);

  const [todos, setTodos] = useState([]);

  /*useEffect(() => {
      if (setTaskEditStage) setTaskEditStage(creationStage);
      return () => {
        if (setTaskEditStage) setTaskEditStage(1);
      };
    }, [creationStage, setTaskEditStage]);*/

  useEffect(() => {
    getLatestTask();

    return () => {};
  }, []);

  const getLatestTask = async () => {
    if (mode !== "edit") return;
    let canUpdate = true;
    try {
      setIsLoadingLatest(true);
      // get fresh project Data

      const [taskResponse, projectResponse] = await Promise.all([
        getCustomerById(token, selectedTask._id),
        getProjectById(token, selectedProject._id),
      ]);

      if (taskResponse.task) {
        try {
          let comparison = compareAsc(
            new Date(selectedTask.updatedAt),
            new Date(taskResponse.task.updatedAt)
          );
          canUpdate = comparison !== 0;

          if (projectResponse.project) {
            let projectComparison = compareAsc(
              new Date(selectedProject.updatedAt),
              new Date(projectResponse.project.updatedAt)
            );
            canUpdate = projectComparison !== 0;
          }
        } catch (err) {
          console.log(err);
          canUpdate = true;
        }

        if (canUpdate) {
          taskResponse.isTaskModalOpen = true;
          taskResponse.updatedProject = projectResponse.project;
          taskUpdated(taskResponse);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      // console.log(canUpdate);
      setIsLoadingLatest(false);
    }
  };

  useEffect(() => {
    setUp({ selectedTask, selectedList, mode, selectedProject });
    return () => {};
  }, [selectedList, selectedTask, selectedProject, mode]);

  const setUp = ({ selectedTask, selectedList, mode, selectedProject }) => {
    //  console.log(selectedTask?.membersToJoin);
    let assignedFrom = getUniqueListBy(
      [
        ...(selectedProject?.team ? selectedProject?.team : []),
        ...(selectedProject?.projectLeader
          ? selectedProject?.projectLeader
          : []),
        ...(selectedTask?.membersToJoin ? selectedTask?.membersToJoin : []),
        ...(selectedProject?.membersToJoin
          ? selectedProject?.membersToJoin.map((el) => el.userId)
          : []),
      ],
      "_id"
    );

    if (mode === "edit") {
      setCreationStage(taskEditStage);
      const assignedTo = [
        ...new Set([
          ...selectedTask.assignedTo.map((user) => user._id),
          ...selectedTask.membersToJoin.map((user) => user._id),
        ]),
      ];
      setTask({
        ...selectedTask,
        startDate: selectedTask.startDate,
        assignedTo,
        membersToJoin: selectedTask.membersToJoin
          ? selectedTask.membersToJoin.map((user) => user._id)
          : [],
        status: selectedList?.status,
      });

      setData({
        ...data,
        ogAssignedTo: assignedTo,
        team: assignedFrom,
        ogTeam: assignedFrom,
        ogTeamFixed: assignedFrom,
        taskEmails: selectedTask.taskEmails ? selectedTask.taskEmails : [],
        emailsToInvite: uniq([
          ...(selectedTask.taskEmails ? selectedTask.taskEmails : []),
          ...(selectedProject.projectLeaderEmails
            ? selectedProject.projectLeaderEmails
            : []),
          ...(selectedProject.teamEmails ? selectedProject.teamEmails : []),
        ]),
      });

      if (
        selectedTask.color &&
        ![...populatedColors, "#FFFFFF"].includes(selectedTask.color)
      ) {
        populatedColors[1] = selectedTask.color;
        setPopulatedColors([...populatedColors]);
      }
    } else {
      setTask({
        ...task,
        status: selectedList?.status,
      });
      setData({
        ...data,
        team: assignedFrom,
        ogTeam: assignedFrom,
        ogTeamFixed: assignedFrom,
      });
    }
  };

  const updateNewTaskDueDate = (event) => {
    let dueDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    setTask({
      ...task,
      dueDate,
    });
  };

  const updateNewTaskStartDate = (event) => {
    let startDate = event.target
      ? event.target.value
      : moment(event).format("DD/MM/YYYY");
    setTask({
      ...task,
      startDate,
    });
  };

  const validationDueDate = (currentDate) => {
    return currentDate.isAfter(
      moment(task.startDate, "DD/MM/YYYY").add(-1, "days")
    );
  };

  const addUser = (user) => {
    task.assignedTo = [...new Set([...task.assignedTo, user._id])];
    addUserToTeamList(user);
    setTask({
      ...task,
    });
  };

  const removeUser = (user) => {
    task.assignedTo = task.assignedTo.filter((userId) => userId !== user._id);
    removeUserFromTeamList(user);
    setTask({
      ...task,
    });
  };

  const findUser = (userId, array) => {
    const found = array.findIndex((user) => user._id === userId);
    return found !== -1;
  };

  const removeUserFromTeamList = (user) => {
    const isUserInOriginalAssignedTo = data.ogAssignedTo.includes(user._id);
    const membersToRemove = isUserInOriginalAssignedTo
      ? [...data.membersToRemove, user._id]
      : [...data.membersToRemove];

    const newTaskMembers = data.newTaskMembers.filter(
      (userId) => userId !== user._id
    );
    const newProjectMembers = data.newTaskMembers.filter(
      (userId) => userId !== user._id
    );

    // console.log(membersToRemove);

    setData({
      ...data,
      membersToRemove,
      newTaskMembers,
      newProjectMembers,
    });
  };

  const addUserToTeamList = (user) => {
    const isUserInOriginalTeam = findUser(user._id, data.ogTeam);
    const isUserInOriginalTeamFixed = findUser(user._id, data.ogTeamFixed);
    const isUserInOriginalAssignedTo = data.ogAssignedTo.includes(user._id);

    // for UI
    const ogTeam = !isUserInOriginalTeam
      ? [user, ...data.ogTeam]
      : [...data.ogTeam];

    // checked fixed for new
    const newProjectMembers = !isUserInOriginalTeamFixed
      ? [...new Set([user._id, ...data.newProjectMembers])]
      : [...data.newProjectMembers];

    const newTaskMembers = !isUserInOriginalAssignedTo
      ? [...new Set([user._id, ...data.newTaskMembers])]
      : [...data.newTaskMembers];

    const membersToRemove = data.membersToRemove.filter(
      (userId) => userId !== user._id
    );

    setData({
      ...data,
      ogTeam,
      newProjectMembers,
      newTaskMembers,
      membersToRemove,
    });
  };

  const isAssignedTo = (userId) => {
    return task.assignedTo.includes(userId);
  };

  const isInvited = (userId) => {
    return task.membersToJoin.includes(userId);
  };

  const searchUser = (searchParam) => {
    searchUserByName(searchParam, token).then((response) => {
      if (response.users) {
        setData({
          ...data,
          team: response.users,
        });
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const searchParams = e.target.value.trim();
    setSearchParams(searchParams);
    if (searchParams) {
      debounce(searchUser(searchParams), 500);
    } else {
      setData({
        ...data,
        team: data.ogTeam,
      });
    }
  };

  const selectAll = () => {
    // data.team represents the users in the ui
    const usersToAdd = data.team.filter(
      (user) => !findUser(user._id, data.ogTeam)
    );

    const users = data.team.map((user) => user._id);
    const assignedTo = [...new Set([...task.assignedTo, ...users])];

    usersToAdd.forEach((user) => {
      addUserToTeamList(user);
    });

    setTask({
      ...task,
      assignedTo,
    });

    setData({
      ...data,
      taskEmails: [...new Set([...data.emailsToInvite, searchParams])].filter(
        (el) => el
      ),
      ogTeam: [...data.ogTeam, ...usersToAdd],
    });
  };

  const unSelectAll = () => {
    const users = data.team.map((user) => user._id);
    const assignedTo = task.assignedTo.filter(
      (userId) => !users.includes(userId)
    );
    data.team.forEach((user) => {
      removeUserFromTeamList(user);
    });
    setTask({
      ...task,
      assignedTo,
    });
  };

  const isAllChecked = (data) => {
    const { team, emailsToInvite, taskEmails } = data;
    if (!team && !emailsToInvite) return false;
    const checkedUsers = team.filter((user) => isAssignedTo(user._id));
    const checkedMails = emailsToInvite.filter((el) => taskEmails.includes(el));

    return (
      team.length === checkedUsers.length &&
      checkedMails.length === taskEmails.length
    );
  };

  const handleAddTask = () => {
    setIsLoading(true);
    if (mode === "edit") {
      const {
        canAddToCalendar,
        newProjectMembers,
        newTaskMembers,
        membersToRemove,
      } = data;

      updateCustomer({
        token,
        task: { ...task, taskEmails: data.taskEmails },
        canAddToCalendar,
        newProjectMembers,
        newTaskMembers,
        membersToRemove,
        projectId: task.project,
      }).then((response) => {
        setIsLoading(false);
        if (response.task) {
          showToast({
            message: {
              title: `${task.taskName} updated successfully!`,
            },
            type: "success",
          });
          taskUpdated(response);
          // console.log(response);
        }
      });
      return;
    }
    const { canAddToCalendar, newProjectMembers } = data;
    addCustomer({
      token,
      task: { ...task, taskEmails: data.taskEmails },
      canAddToCalendar,
      newProjectMembers,
      todos,
    }).then((response) => {
      setIsLoading(false);
      if (response.task) {
        showToast({
          message: {
            title: "Customer Created",
            description: "You have successfully created a new customer!",
          },
          type: "success",
        });
        taskCreated(response);
      }
    });
  };

  const handleSelectedColor = (color) => {
    if (![...populatedColors, "#FFFFFF"].includes(color)) {
      populatedColors[1] = color;
      setPopulatedColors([...populatedColors]);
    }
    setTask({
      ...task,
      color,
    });
  };

  const FormProgress = ({ maxStage }) => {
    return (
      <div className="form-progress">
        <span>
          {creationStage} of {maxStage}
        </span>
        <div className="d-flex justify-content-between align-items center w-100">
          <div className="divider"></div>
          <CheckmarkIcon onClick={() => setCreationStage(1)} />
          {creationStage >= 2 ? (
            <CheckmarkIcon onClick={() => setCreationStage(2)} />
          ) : (
            <CheckmarkGreyIcon />
          )}

          {maxStage > 2 &&
            (creationStage >= 3 ? (
              <CheckmarkIcon onClick={() => setCreationStage(2)} />
            ) : (
              <CheckmarkGreyIcon />
            ))}
        </div>
      </div>
    );
  };

  const toggleColorPicker = () => {
    eventBus.dispatch("show-color-picker", {});
  };

  const addMail = () => {
    if (searchParams === user.email) {
      showToast({
        message: {
          title: "You cannot invite yourself",
        },
        type: "error",
      });
      return;
    }
    setSearchParams("");
    setData({
      ...cloneDeep(data),
      team: data.ogTeam,
      emailsToInvite: [...new Set([...data.emailsToInvite, searchParams])],
      taskEmails: [...new Set([...data.taskEmails, searchParams])],
    });
  };

  const removeEmail = (email) => {
    setData({
      ...data,
      taskEmails: [
        ...new Set([...data.taskEmails.filter((el) => el !== email)]),
      ],
    });
  };

  const addEmail = (email) => {
    setData({
      ...data,
      taskEmails: [...new Set([...data.taskEmails, email])],
    });
  };

  const isMailInvited = (email) => {
    return data.taskEmails.includes(email);
  };

  const removeTodo = async (index) => {
    if (
      await DialogModal({
        title: "Delete",
        description: `Are you sure you want to delete ?`,
        type: "warning",
      })
    ) {
      setTodos((oldValue) => {
        oldValue = oldValue.filter((el, i) => i !== index);
        return [...oldValue];
      });
    }
  };

  const checkTodo = (index, checked) => {
    setTodos((oldValue) => {
      oldValue[index].checked = checked;
      return [...oldValue];
    });
  };

  const addTodo = (value) => {
    setTodos((oldValue) => [
      ...oldValue,
      {
        text: value,
        modifiedBy: user._id,
        checked: false,
      },
    ]);
  };

  const editTodo = (index, text) => {
    setTodos((oldValue) => {
      oldValue[index].text = text;
      return [...oldValue];
    });
  };

  const selectAllTodo = (checked) => {
    setTodos((oldValue) => {
      oldValue = oldValue.map((el, i) => ({ ...el, checked }));
      return [...oldValue];
    });
  };

  return (
    <>
      <Modal
        show={showTaskModal}
        onHide={() => {
          if (setTaskEditStage) setTaskEditStage(1);
          setShowTaskModal(false);
        }}
        dialogClassName={`${
          creationStage === 1
            ? "task-modal customer-modal"
            : creationStage === 3
            ? "assign-modal"
            : "todo-modal"
        }`}
        backdropClassName="global-backdrop max-z-index"
        animation={false}
        centered
      >
        {isLoadingLatest && (
          <div
            style={{
              zIndex: "1000",
            }}
            className="position-absolute text-center w-100 h-100 bg-white d-flex flex-column align-items-center justify-content-center rounded"
          >
            <Spinner spinnerHeight={`2rem`} />
            <p className="h4">Please wait...</p>
          </div>
        )}
        {creationStage === 1 && (
          <>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                mode === "edit" ? setCreationStage(3) : setCreationStage(2);
              }}
              autoComplete="off"
            >
              <Modal.Header
                style={{ borderTop: `4px solid ${selectedList.color}` }}
              >
                <Modal.Title id="title">
                  {mode === "edit" ? "Edit Customer" : "Create New Customer"}
                </Modal.Title>

                <button id="close-btn" onClick={() => setShowTaskModal(false)}>
                  <CloseIcon />
                </button>
              </Modal.Header>
              <Modal.Body>
                <Form.Group controlId="taskName">
                  <Form.Label>
                    Business Name<sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Business Name"
                    value={task.taskName}
                    onChange={(e) => {
                      setTask({
                        ...task,
                        taskName: e.target.value,
                      });
                    }}
                    autoComplete="off"
                    required
                  />
                </Form.Group>

                <Form.Group controlId="description">
                  <Form.Label>
                    Company Service Description
                    <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={task.taskDesc}
                    onChange={(e) => {
                      setTask({
                        ...task,
                        taskDesc: e.target.value,
                      });
                    }}
                    placeholder="Company service description"
                    required
                  />
                </Form.Group>
                <div className="d-block d-md-flex date-duration justify-space-between">
                  <div className="starting">
                    <Form.Group controlId="description">
                      <Form.Label>
                        Company Address<sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        as="input"
                        value={task.taskDetail}
                        onChange={(e) => {
                          setTask({
                            ...task,
                            taskDetail: e.target.value,
                          });
                        }}
                        placeholder="Company address"
                        required
                      />
                    </Form.Group>
                  </div>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <div className="starting">
                    <Form.Group controlId="description">
                      <Form.Label>Company Website Address</Form.Label>
                      <Form.Control
                        as="input"
                        value={task.taskWebsiteAddress}
                        onChange={(e) => {
                          setTask({
                            ...task,
                            taskWebsiteAddress: e.target.value,
                          });
                        }}
                        placeholder="Company website address"
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="d-block d-md-flex date-duration justify-space-between">
                  <div className="starting">
                    <Form.Group controlId="Customer Type" required>
                      <Form.Label>
                        Customer Type<sup className="text-danger">*</sup>
                      </Form.Label>

                      <Form.Control
                        as={"select"}
                        onChange={(e) => {
                          setTask({
                            ...task,
                            customerType: e.target.value,
                          });
                        }}
                        value={task.customerType}
                        required
                      >
                        <option value="">Select Customer Type</option>
                        <option value="Walk In">Walk In</option>
                        <option value="Wholesaler">Wholesaler</option>
                        <option value="Distributor">Distributor</option>
                        <option value="Others">Others</option>
                      </Form.Control>
                      <ChevronDownIcon className="form-icon" />
                    </Form.Group>
                  </div>
                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                  <div className="starting align-items-center">
                    <Form.Group controlId="About Us">
                      <Form.Label>
                        How did you know about us
                        <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        as={"select"}
                        onChange={(e) => {
                          setTask({
                            ...task,
                            aboutUs: e.target.value,
                          });
                        }}
                        value={task.aboutUs}
                        required
                      >
                        <option value="">Select an option</option>
                        <option value="Walk In">Walk In</option>
                        <option value="Flyers">Flyers</option>
                        <option value="Radio">Radio</option>
                        <option value="Bill board">Bill board</option>
                        <option value="Friends">Friends</option>
                        <option value="Television">Television</option>
                        <option value="Website">Website</option>
                        <option value="Search Engine ( Google, Bing, Yahoo, etc)">
                          Search Engine ( Google, Bing, Yahoo, etc)
                        </option>
                      </Form.Control>
                      <ChevronDownIcon className="form-icon" />
                    </Form.Group>
                  </div>
                </div>

                <div className="d-flex date-duration align-items-center">
                  <Form.Group controlId="productInterest" className="w-100">
                    <Form.Label>
                      Product of Interest<sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Product of Interest"
                      value={task.productInterest}
                      onChange={(e) => {
                        setTask({
                          ...task,
                          productInterest: e.target.value,
                        });
                      }}
                      autoComplete="off"
                      required
                    />
                  </Form.Group>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <FormProgress maxStage={mode === "edit" ? 2 : 3} />

                <button className="btn btn-primary" type="submit">
                  Next
                </button>
              </Modal.Footer>
            </Form>
          </>
        )}
        {creationStage === 3 && (
          <>
            <Modal.Header
              style={{ borderTop: `4px solid ${selectedList.color}` }}
            >
              <Modal.Title id="title">Add user..</Modal.Title>

              <button id="close-btn" onClick={() => setShowTaskModal(false)}>
                <CloseIcon />
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="search">
                <div className="search-box">
                  <input
                    placeholder="Search members"
                    value={searchParams}
                    onChange={(e) => handleSearch(e)}
                  />
                  <SearchIcon className="search-icon" />
                </div>
              </div>

              <div className="user-wrapper">
                <div className="action align-items-center">
                  <div className="grid-item text-secondary">
                    {data.team.length || data.taskEmails.length ? (
                      <span>
                        {isAllChecked(data) ? (
                          <CheckboxIcon onClick={() => unSelectAll()} />
                        ) : (
                          <UncheckedboxIcon onClick={() => selectAll()} />
                        )}
                      </span>
                    ) : (
                      <span className="bg-light">
                        <UncheckedboxIcon />
                      </span>
                    )}
                  </div>
                  <div>
                    <span>Select All</span>
                  </div>
                </div>

                <div className="user-list">
                  {!validateEmail(searchParams) ? (
                    <>
                      {data.emailsToInvite.map((email, index) => (
                        <div className="user" key={index}>
                          <div className="grid-item">
                            {isMailInvited(email) ? (
                              <span onClick={() => removeEmail(email)}>
                                <CheckboxIcon />
                              </span>
                            ) : (
                              <span onClick={() => addEmail(email)}>
                                <UncheckedboxIcon className="not-checked" />
                              </span>
                            )}
                          </div>
                          <div className="grid-item">
                            <Avatar
                              key={index}
                              width={48}
                              height={48}
                              user={{ name: { first: email }, photo: "" }}
                              zIndex={1}
                              showLeader={false}
                            />
                          </div>
                          <div className="grid-item d-flex flex-column justify-content-start">
                            <span className="name">{email}</span>
                          </div>
                        </div>
                      ))}
                      {data.team.map((user, index) => (
                        <div className="user" key={index}>
                          <div className="grid-item">
                            {isAssignedTo(user._id) ? (
                              <CheckboxIcon onClick={() => removeUser(user)} />
                            ) : (
                              <UncheckedboxIcon onClick={() => addUser(user)} />
                            )}
                          </div>
                          <div className="grid-item">
                            <Avatar
                              key={index}
                              width={48}
                              height={48}
                              user={user}
                              zIndex={1}
                            />
                          </div>
                          <div className="grid-item d-flex flex-column justify-content-start">
                            <span className="name">{`${user.name?.first} ${user.name?.last}`}</span>
                            <span className="email">
                              @{user.username}{" "}
                              {isInvited(user._id) ? (
                                <span className="badge bg-light p-1 rounded">
                                  invited
                                </span>
                              ) : null}
                            </span>
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="add-new-email-container">
                      <div>
                        <span className="dy-text">
                          Do you want to add this email address?
                        </span>
                        "<em className="em-text">{searchParams}</em>"
                      </div>
                      <span
                        className="yes-text bg-primary p-cursor"
                        onClick={() => addMail()}
                      >
                        Yes please
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <FormProgress maxStage={mode === "edit" ? 2 : 3} />
              <div>
                <button
                  className="btn btn-outline-primary mr-3"
                  type="button"
                  onClick={() => setCreationStage(2)}
                >
                  Back
                </button>
                <button
                  disabled={isLoading}
                  className="btn btn-primary"
                  onClick={() => handleAddTask()}
                >
                  {isLoading
                    ? "Please wait..."
                    : mode === "edit"
                    ? "Save Changes"
                    : "Create Customer"}
                </button>
              </div>
            </Modal.Footer>
          </>
        )}
        {creationStage === 2 && mode !== "edit" && (
          <>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                setCreationStage(3);
              }}
              autoComplete="off"
            >
              <Modal.Header
                className="px-4"
                style={{ borderTop: `4px solid ${selectedList.color}` }}
              >
                <Modal.Title id="title">Add Contact Persons</Modal.Title>
                <button id="close-btn" onClick={() => setShowTaskModal(false)}>
                  <CloseIcon />
                </button>
              </Modal.Header>

              <Modal.Body className="px-4 pt-0">
                <div class="row">
                  <div className="col-md-6 col-sm-12">
                    <Form.Group controlId="firstName">
                      <Form.Label>
                        First Name<sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        className="py-3"
                        type="text"
                        placeholder="First Name"
                        value={task.name.first}
                        onChange={(e) => {
                          setTask({
                            ...task,
                            name: {
                              ...task.name,
                              first: e.target.value,
                            },
                          });
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <Form.Group controlId="lastName">
                      <Form.Label>
                        Last Name<sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        className="py-3"
                        type="text"
                        placeholder="Last Name"
                        value={task.name.last}
                        onChange={(e) => {
                          setTask({
                            ...task,
                            name: {
                              ...task.name,
                              last: e.target.value,
                            },
                          });
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </div>
                </div>

                <div class="row">
                  <div className="col-md-6 col-sm-12">
                    <Form.Group controlId="email">
                      <Form.Label>
                        Email<sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        className="py-3"
                        type="email"
                        placeholder="Email"
                        value={task.email}
                        onChange={(e) => {
                          setTask({
                            ...task,
                            email: e.target.value,
                          });
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <Form.Group controlId="phone">
                      <Form.Label>
                        Phone<sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        className="py-3"
                        type="text"
                        placeholder="Phone"
                        value={task.phone}
                        onChange={(e) => {
                          setTask({
                            ...task,
                            phone: e.target.value,
                          });
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </div>
                </div>

                <div class="row">
                  <div className="col-md-12 col-sm-12">
                    <Form.Group controlId="position">
                      <Form.Label>
                        Position<sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        className="py-3"
                        type="text"
                        placeholder="Position"
                        value={task.position}
                        onChange={(e) => {
                          setTask({
                            ...task,
                            position: e.target.value,
                          });
                        }}
                        autoComplete="off"
                      />
                    </Form.Group>
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer className="px-4">
                <div>
                  <button
                    className="btn btn-outline-primary mr-3"
                    type="button"
                    onClick={() => setCreationStage(1)}
                  >
                    Back
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Next
                  </button>
                </div>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.userReducer.token,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerModal);
