import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";

import Topbar from "../topbar/Topbar";

import "../../assets2/css/bootstrap.min.css";
import "../../assets/scss/board.scss";
import "../../assets/inter-font/stylesheet.css";

import PaperclipIcon from "mdi-react/PaperclipIcon";
import PlusIcon from "mdi-react/PlusIcon";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import TaskDetailsModal from "./modals/TaskDetails";
import Spinner from "./../global/Spinner";

import CheckCircleIcon from "mdi-react/CheckCircleIcon";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import EyeIcon from "mdi-react/EyeIcon";
import LinkVariantIcon from "mdi-react/LinkVariantIcon";
import PinOffIcon from "mdi-react/PinOffIcon";
import { Dropdown } from "react-bootstrap";
import FileIcon from "mdi-react/FileDocumentIcon";
import ClipboardIcon from "mdi-react/ClipboardIcon";
import CogIcon from "mdi-react/CogOutlineIcon";
import ProjectDetailsModal from "./modals/ProjectDetails";
import {
  AddUserIcon,
  ColorCheckedIcon,
  NoSearchResults,
  ProjectIcon,
  SearchIcon,
} from "./components/Icons";
import ProjectModal from "./modals/PipelineModal";
import CustomerModal from "./modals/CustomerModal";
import DialogModal from "./modals/DialogModal";
import ConversationForm from "./modals/ConversationForm";
import UploadQuotation from "./modals/UploadQuotation";
import { socket } from "../../sockets";
import { v4 as uuidv4 } from "uuid";
// import FileModal from "./modals/FileModal";
import {
  darkTaskColors,
  getFileDetails,
  showToast,
  lightTaskColors,
  photos,
  newPhotos,
  styleHelper,
  CopyToClipboard,
  sliderCheckbox,
  formatSlashedDate,
  getUniqueListBy,
  formatDateOnly,
  allProjectMembers,
} from "./utils";
import { cloneDeep, update, isEmpty } from "lodash";

import {
  getProjectsIBelongTo,
  getTasksInProjectIBelongTo,
  deleteTask,
} from "../../services/crmServices";

import { getClients } from "../../services/clientServices";
import {
  getTeamsIBelongTo,
  getTeamsICreated,
} from "../../services/teamServices";
import { getCompanyUsers } from "../../services/userServices";
import { format, yearsToQuarters } from "date-fns";
import TaskCard from "./components/TaskCard";
import Mention from "./components/Mention";
import { combineData } from "../../utils";

import {
  addProject,
  appendUploadedFileId,
  deleteList,
  getProjectAndTask,
  getProjectById,
} from "../../services/projectServices";
import uploadClient from "../../helper/uploadClient";
import { updateFileName, searchUserByEmail } from "../../services/userServices";
import { AddNewList } from "./components/AddNewList";
import StageHeader from "./components/StageHeader";
import CloseIcon from "mdi-react/CloseIcon";
import Avatar from "./components/Avatar";
import {
  setUploadFile,
  uploadFile,
} from "../../store/actions/uploadFileActions";
import BoardSettingsContainer from "../tasks/board-settings/BoardSettingsContainer";
import { URLS } from "../urls";
import { getBoardSettings } from "../../services/boardSettingsService";
import {
  populateNotifications,
  saveSettings,
  setPersonalised,
} from "../../store/actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import arrayMove from "array-move";
import PinIcon from "mdi-react/PinIcon";
import TextLongIcon from "mdi-react/TextIcon";
import MobileToolbar from "./components/MobileToolbar";
import { Link, useParams } from "react-router-dom";
import eventBus, { useCustomEventListener } from "../../helper/EventBus";
import { CopyLinkIcon } from "../topbar/Icons";
import {
  useAsyncReference,
  useDebounce,
  useOnClickOutside,
  useStateRef,
} from "./custom-hooks";
import ClockOutlineIcon from "mdi-react/ClockOutlineIcon";
import ForumOutlineIcon from "mdi-react/ForumOutlineIcon";
import ProjectSelector from "./components/ProjectSelector";
import ChartLineIcon from "mdi-react/ChartLineIcon";
// import UploadToModal from "./modals/UploadToModal";
import { fetchQuotes } from "../../services/customerServices";

function Board({
  user,
  token,
  hasAccountExpired,
  setUploadFile,
  fileProgress,
  uploadFile,
  boardSettings,
  settings,
  saveSettings,
  usage,
  match,
  history,
  populateNotifications,
  setPersonalised,
}) {
  const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;
  const cloudDriveFrontEnd = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;
  const bgImages = [...newPhotos, ...photos];
  const initialColorPickerState = {
    showColorPicker: false,
    colorPickerStyle: {
      top: 0,
      left: 0,
    },
    type: "",
    checkedColor: "",
  };
  const initialBoardSearchResults = {
    projects: [],
    tasks: [],
  };

  let colorPickerRef = useRef(null);
  let projectDropdownRef = useRef(null);
  let boardSearchResultsRef = useRef(null);
  let ProjectSelectorRef = useRef(null);

  const [showProjectSelector, setShowProjectSelector] = useState(false);
  const [showProjectDetailsModal, setShowProjectDetailsModal] = useState(false);
  const [showTaskDetailsModal, setShowTaskDetailsModal] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showProjectEditModal, setShowProjectEditModal] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [showTaskEditModal, setShowTaskEditModal] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const [showConversationForm, setShowConversationForm] = useState(false);
  const [showUploadQuotation, setShowUploadQuotation] = useState(false);
  // const [showBoardSettings, setShowBoardSettings] = useState(true);
  const [showGotoBoard, setShowGotoBoard] = useState(false);
  const [showToolbarDetails, setShowToolbarDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [pinnedProject, setPinnedProject] = useState("");
  const [colorPickerState, setColorPickerState] = useState(
    initialColorPickerState
  );
  const [colors, setColors] = useState([]);
  const [data, setData] = useState({
    projects: [],
    // tasks: [],
    // selectedProject: {},
    pinnedProject: "",
    teamsICreated: [],
    clientList: [],
    employeesList: [],
    ongoingUploads: [],
    teamsIBelongTo: [],
    // justCreatedProject: {},
  });
  const boardRef = useRef(null);
  let mentionRef = useRef(null);
  let searchStringRef = useRef(null);
  const [usersListStyle, setUsersListStyle] = useState({
    isUsersListShow: false,
  });
  const [selectedProject, _setSelectedProject] = useState({});
  const _selectedProject = useRef({});
  const setSelectedProject = (data) => {
    _selectedProject.current = data;
    _setSelectedProject(data);
  };

  const [selectedTask, setSelectedTask] = useState({});

  // --
  const [list, _setList] = useState([]);
  const _list = useRef([]);
  const setList = (data) => {
    _list.current = data;
    _setList(data);
  };
  // --

  const [selectedList, setSelectedList] = useState({});
  const [usersList, setUsersList] = useState({});
  const [projectUsersList, setProjectUsersList] = useState({});

  const [projectEditStage, setProjectEditStage] = useState(1);
  const [taskEditStage, setTaskEditStage] = useState(1);
  // const [projectFilesDataList, setProjectFilesDataList] = useState([]);
  const [isShared, setIsShared] = useState(false);

  let firstTaskSelectedUpdate = useRef(true);
  let firstRender = useRef(true);
  let hasSetUpUi = useRef(false);
  const [hidePage, setHidePage] = useState(false);
  const [hideProjectDropdown, setHideProjectDropdown] = useState(true);

  const [showBoardSearch, setShowBoardSearch] = useState(true);
  const [searchString, setSearchString] = useState("");

  const [boardSearchResults, setBoardSearchResults] = useState(
    initialBoardSearchResults
  );
  const [hasSearched, setHasSearched] = useState(false);
  const debouncedSearchString = useDebounce(searchString, 500);
  const [Quotes, setQuotes] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    let data = await fetchQuotes(token, { taskId: selectedTask._id });
    setQuotes([...data.data]);
  }

  useEffect(() => {
    if (debouncedSearchString) {
      searchBoard(debouncedSearchString);
    } else {
      setBoardSearchResults(initialBoardSearchResults);
      setHasSearched(false);
    }
  }, [debouncedSearchString]);

  useEffect(() => {
    if (showBoardSearch && searchStringRef.current) {
      searchStringRef.current.focus();
    } else {
      // setSearchString("");
    }
  }, [showBoardSearch, searchStringRef]);

  useOnClickOutside(boardSearchResultsRef, (e) => {
    let element = document.getElementById("desktop-settings");
    if (e.target !== element && !element.contains(e.target)) {
      // setShowBoardSearch(false);
    }
  });

  useOnClickOutside(ProjectSelectorRef, (e) => {
    let projectEl = document.getElementById("select-holder");
    if (e.target !== projectEl && !projectEl.contains(e.target)) {
      setShowProjectSelector(false);
    }
  });

  eventBus.useCustomEventListener("showTaskDetailsModal", (val) =>
    setShowTaskDetailsModal(val)
  );

  useEffect(() => {
    /* if (hasSetUpUi.current || showTaskDetailsModal || showProjectDetailsModal) {
      if (showTaskDetailsModal) {
        history.push({
          pathname: `/tasks-board?projectId=${selectedTask.project}&taskId=${selectedTask._id}`,
        });
      } else if (showProjectDetailsModal) {
        history.push({
          pathname: `/tasks-board?projectId=${selectedProject._id}`,
        });
      } else {
        history.push({
          pathname: `/tasks-board?projectId=${selectedProject._id}`,
        });
      }
    }
    */
  }, [
    showTaskDetailsModal,
    showProjectDetailsModal,
    selectedProject,
    selectedTask,
    history,
  ]);

  useOnClickOutside(projectDropdownRef, () => setHideProjectDropdown(true));

  useEffect(() => {
    socket.on("fx:task-comment-unread-count", (response) => {
      // console.log("new unread", response);
      if (response?.success) {
        const { task } = response;
        updateTaskUi({
          taskToUpdate: task,
          newData: { unreadCount: task.unreadCount },
        });
      }
    });

    socket.on("fx:broadcast-task-status-updated", (response) => {
      if (response?.success) {
        const { task, unreadCount, newStatus, oldStatus } = response;
        console.log(response);
        moveTaskToList({
          taskToMove: { ...task, unreadCount },
          newStatus,
          oldStatus,
        });
      }
    });

    return () => {
      socket.off("fx:task-comment-unread-count", () => {});
      socket.off("fx:broadcast-task-status-updated", () => {});
    };
  }, []);

  const socketConnected = () => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    //  console.log("jgjg", _selectedProject.current);
    if (_selectedProject.current._id) {
      handleTasksInProjectIBelongTo({
        projectId: _selectedProject.current._id,
        selectedProject: _selectedProject.current,
        emptyList: false,
        showError: false,
      });
    }
  };

  eventBus.useCustomEventListener("socket-connected", socketConnected);

  // when we close our project details modal ensure gotoboard is false
  useEffect(() => {
    if (!showProjectDetailsModal) {
      setShowGotoBoard(false);
    }
    return () => {};
  }, [showProjectDetailsModal]);

  useEffect(() => {
    // /:sharableId/:projectId
    const fullUrl = window.location.pathname.split("/");
    if (fullUrl.includes("project-invite")) {
      setHidePage(true);
      const sharableId = fullUrl[2];
      const projectId = fullUrl[3];

      // get single project
      setUpProjectInvite({
        projectId,
        sharableId,
      });
    } else if (fullUrl.includes("task-invite")) {
      setHidePage(true);
      // console.log("ss", fullUrl);
      const sharableId = fullUrl[2];
      const projectId = fullUrl[3];
      const taskId = fullUrl[4];

      setUpTaskInvite({
        projectId,
        sharableId,
        taskId,
      });
    } else {
      setUp();
    }
    return () => {};
  }, []);

  useEffect(() => {
    setProjectUsersList({
      leaders: [
        ...(selectedProject.projectLeader ? selectedProject.projectLeader : []),
      ],
      team: [...(selectedProject.team ? selectedProject.team : [])],
    });
    console.log(selectedList);
    return () => {};
  }, [selectedProject]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        // console.log(event.target);
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !ref.current.classList.contains("d-none")
        ) {
          setColorPickerState(initialColorPickerState);
          setUsersListStyle({
            isUsersListShow: false,
          });
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(colorPickerRef);
  useOutsideAlerter(mentionRef);

  const toggleProjectNotification = ({ projectId, enabled }) => {
    // alert("djdj");
    socket.emit(
      "fx:toggle-notification",
      {
        token,
        projectId,
        enabled,
      },
      (response) => {
        setSelectedProject({
          ...selectedProject,
          notificationEnabled: enabled,
        });
        showToast({
          message: {
            title: enabled ? "Notications enabled" : "Notifications disabled",
          },
          type: "success",
        });
      }
    );
  };

  const toggleTaskNotifications = ({ _id, project, enabled, ...rest }) => {
    // alert(enabled);
    socket.emit(
      "fx:toggle-task-notification",
      {
        token,
        taskId: _id,
        enabled: !enabled,
        projectId: project,
      },
      (response) => {
        updateTaskUi({
          taskToUpdate: {
            _id,
            project,
            ...rest,
          },
          newData: {
            enabled: !enabled,
          },
        });
        showToast({
          message: {
            title: !enabled ? "Notications enabled" : "Notifications disabled",
          },
          type: "success",
        });
      }
    );
  };

  const showUsersList = (e, users, options = { ignoreTop: false }) => {
    console.log(users);

    let x = e.clientX,
      y = e.clientY,
      sx = e.screenX,
      sy = e.screenY,
      w = window.innerWidth,
      h = window.innerHeight,
      // task2ChatStyle.bottom = sy - y + 45;
      top = y,
      // If screen left is < 300 Else if screen left
      // left = x < 150 ? 150 : w - x < 150 ? x - 175 : x;
      left = x,
      bottom = null;

    if (h - y < 310) {
      top = y - 310;
    }
    if (w - x < 330) {
      left = x - 330;
    }

    setUsersList({
      ...users,
    });
    if (styleHelper.isMobile) {
      setUsersListStyle({
        isUsersListShow: true,
        top: "unset",
        left: 0,
        bottom: 0,
        width: "100vw",
      });
    } else {
      // console.log(y);
      if (y < 330 && !options.ignoreTop) {
        top = users?.assignedTo && users.assignedTo.length > 5 ? "unset" : top;
      }
      setUsersListStyle({
        isUsersListShow: true,
        top,
        left,
      });
    }
  };

  const loadTask = async ({ projectId, taskId, commentId, data }) => {
    if (data.projects.length) {
      /* history.replace({
        pathname: `/tasks-board?projectId=${projectId}&taskId=${taskId}`,
      });
      */
      history.replace({
        pathname: `/tasks-board`,
      });
      const selectedProject = cloneDeep(data.projects).find(
        (project) => project?._id === projectId
      );

      // console.log("all projects", data.projects);
      // console.log("found project", selectedProject);

      // for autoshow
      firstTaskSelectedUpdate.current = true;

      setSelectedProject({ ...cloneDeep(selectedProject) });
      handleTasksInProjectIBelongTo({
        projectId,
        selectedProject: { ...cloneDeep(selectedProject) },
        taskIdParam: taskId,
        projectIdParam: projectId,
        commentIdParam: commentId,
      });
    }
  };

  eventBus.useCustomEventListener("loadTask", ({ projectId, taskId, c }) => {
    if (data?.projects) {
      loadTask({
        projectId,
        taskId,
        commentId: c,
        data,
      });
    }
  });

  const getEssentials = async () => {
    let clientsResponse = getClients(token);
    let teamsICreatedResponse = getTeamsICreated(token);
    let companyUsersResponse = getCompanyUsers(token);
    const teamResponse = getTeamsIBelongTo(token);

    await Promise.all([
      clientsResponse,
      teamsICreatedResponse,
      companyUsersResponse,
      teamResponse,
    ])
      .then(([res1, res2, companyUsersResponse, teamResponse]) => {
        let clientsResponse = res1;
        let teamsICreatedResponse = res2;
        const employeesList = companyUsersResponse.users;
        const teamsIBelongTo = teamResponse.teams || [];

        setData((data) =>
          combineData(data, {
            clientList: clientsResponse.clients || [],
            teamsICreated: teamsICreatedResponse.teams || [],
            employeesList,
            teamsIBelongTo,
          })
        );
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (showProjectModal || showProjectEditModal) {
      getEssentials();
    }
  }, [showProjectModal, showProjectEditModal]);

  const setUp = async () => {
    let clientsResponse = getClients(token);
    let teamsICreatedResponse = getTeamsICreated(token);
    let projectsResponse = getProjectsIBelongTo(token);
    let companyUsersResponse = getCompanyUsers(token);
    let settings = getBoardSettings(token);
    const teamResponse = getTeamsIBelongTo(token);

    await Promise.all([
      clientsResponse,
      teamsICreatedResponse,
      projectsResponse,
      companyUsersResponse,
      settings,
      teamResponse,
    ])
      .then(
        ([
          res1,
          res2,
          projectsResponse,
          companyUsersResponse,
          settings,
          teamResponse,
        ]) => {
          let clientsResponse = res1;
          let teamsICreatedResponse = res2;
          const { projects, pinnedProject } = projectsResponse;
          const employeesList = companyUsersResponse.users;
          // console.log(settings);
          if (settings && settings.boardSettings) {
            saveSettings({ ...boardSettings, ...settings.boardSettings });
          }
          const teamsIBelongTo = teamResponse.teams || [];

          setData((data) =>
            combineData(data, {
              projects,
              pinnedProject,
              clientList: clientsResponse.clients || [],
              teamsICreated: teamsICreatedResponse.teams || [],
              employeesList,
              teamsIBelongTo,
            })
          );

          const searchParams = new URLSearchParams(window.location.search);
          const projectIdParam = searchParams.get("projectId");
          const taskIdParam = searchParams.get("taskId");
          const commentIdParam = searchParams.get("c");
          const showModalParam = searchParams.get("m");

          // console.log(projects);

          let project =
            projectIdParam && findIdInObjArray(projects, projectIdParam)
              ? projects.find((project) => project._id === projectIdParam)
              : sessionStorage.getItem("lastViewedProjectId")
              ? projects.find(
                  (project) =>
                    project._id ===
                    sessionStorage.getItem("lastViewedProjectId")
                )
              : pinnedProject && findIdInObjArray(projects, pinnedProject)
              ? projects.find((project) => project._id === pinnedProject)
              : projects[0];
          // : projects[Math.floor(Math.random() * projects.length - 1)];

          if (!project) {
            project = projects[0];
          }

          setTimeout(() => {
            if (pinnedProject) setPinnedProject(pinnedProject);
            // 60fe96a6a8d5842b0ce7ed83
            setSelectedProject({ ...project });
            handleTasksInProjectIBelongTo({
              projectId: project?._id,
              selectedProject: project,
              taskIdParam,
              projectIdParam,
              showModalParam,
              commentIdParam,
            });
            // setShowTaskDetailsModal(true);
            hasSetUpUi.current = true;
          }, 600);
        }
      )
      .catch((err) => console.error(err));
  };

  const setUpProjectInvite = async ({ projectId, sharableId }) => {
    // TODO - check sharable id on server
    try {
      const { project, success, error } = await getProjectById(
        token,
        projectId,
        sharableId
      );
      // console.log(project);
      if (success && project) {
        setIsShared(true);
        setData((data) =>
          combineData(data, {
            projects: [{ ...project }],
          })
        );
        setSelectedProject({ ...project });
        setShowProjectDetailsModal(true);
      } else {
        showToast({
          message: {
            title: "Unable to get Project",
            description:
              typeof error === "string"
                ? error
                : "Please, Ensure the link is valid",
          },
          type: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setUpTaskInvite = async ({ projectId, sharableId, taskId }) => {
    try {
      // get
      const { project, task, success, error } = await getProjectAndTask({
        projectId,
        sharableId,
        taskId,
      });

      if (success && project) {
        setIsShared(true);
        setData((data) =>
          combineData(data, {
            projects: [{ ...project }],
          })
        );
        setSelectedProject({ ...project });
        setTimeout(() => {
          let list = project.list;
          list.forEach((list) => {
            list.tasks = [task].filter((task) => task.status === list.status);
          });
          setList(list);
          autoShowTaskDetailsModal({ list: [...list], taskIdParam: task._id });
        }, 600);
      } else {
        showToast({
          message: {
            title: "Unable to get Project",
            description:
              typeof error === "string"
                ? error
                : "Please, Ensure the link is valid",
          },
          type: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const findIdInObjArray = (array, idToFind) => {
    return array.find((el) => el._id === idToFind);
  };

  const autoShowTaskDetailsModal = ({
    list,
    taskIdParam,
    showModalParam,
    commentIdParam = "",
  }) => {
    // ?projectId=60ea3e9e8e2c9854e8eed7f9&taskId=60ea3ed48e2c9854e8eed801
    if (list && firstTaskSelectedUpdate.current) {
      const allTasksInProject = list.map((list) => list.tasks).flat();
      const task = allTasksInProject.find((task) => task._id === taskIdParam);
      if (task) {
        showTaskDetails({
          task,
          list: list.find((list) => list.status === task.status),
          showModalParam,
          commentIdParam,
        });
      }
      firstTaskSelectedUpdate.current = false;
    }
  };

  const showTaskDetails = ({ task, list, showModalParam, commentIdParam }) => {
    history.replace({
      pathname: `/tasks-board`,
    });
    setSelectedTask({
      ...task,
      selectedCommentId: commentIdParam,
    });
    setSelectedList({
      ...list,
    });
    if (showModalParam != "f") {
      setShowTaskDetailsModal(true);
    }
  };

  const handleTasksInProjectIBelongTo = async ({
    projectId,
    selectedProject,
    taskIdParam,
    projectIdParam,
    showModalParam,
    commentIdParam,
    emptyList = true,
    showError = true,
  }) => {
    //  alert("d");
    try {
      setIsLoading(true);
      if (emptyList) setList([]);
      const res = await getTasksInProjectIBelongTo(token, projectId);
      if (res && res.success) {
        const { tasks, notificationEnabled } = res;
        // set notification-toggle
        setSelectedProject({ ...selectedProject, notificationEnabled });

        let list;

        if (selectedProject?.list) {
          list = selectedProject.list;
        } else {
          /*list = [
            { title: "Pending", status: "AWAITING", color: "#FE5B52" },
            { title: "In Progress", status: "INCOMPLETE", color: "#3F96D1" },
            { title: "Under Review", status: "UNDER_REVIEW", color: "#FFB147" },
            { title: "Completed", status: "COMPLETE", color: "#0ACCA9" },
          ];
          */
          showToast({
            message: {
              title: "No list found",
            },
            type: "error",
          });
          return;
        }

        list.forEach((list) => {
          list.tasks = tasks.filter((task) => task.status === list.status);
        });
        setList(list);
        if (projectIdParam && taskIdParam) {
          autoShowTaskDetailsModal({
            list: [...list],
            taskIdParam,
            commentIdParam,
            showModalParam,
          });
        } else if (projectIdParam) {
          if (showModalParam !== "f") {
            setShowProjectDetailsModal(true);
          }
        }
        document.querySelector(
          "[data-rbd-droppable-id='all-columns']"
        ).scrollLeft = 0;
        // boardRef.current.scrollLeft = 0;
      } else {
        throw Error();
      }
    } catch (error) {
      console.log(error);
      if (showError) {
        showToast({
          message: {
            title: "Unable to get Tasks.",
          },
          type: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectProject = (projectId) => {
    const selectedProject = cloneDeep(data.projects).find(
      (project) => project?._id === projectId
    );
    setSelectedProject(selectedProject);
    sessionStorage.setItem("lastViewedProjectId", selectedProject._id);
    handleTasksInProjectIBelongTo({
      projectId,
      selectedProject,
    });
    setShowProjectSelector(false);
  };

  const handleProjectCreated = ({
    project,
    projectFilesDataList,
    folder_id,
    response,
  }) => {
    // setProjectFilesDataList(projectFilesDataList);
    setShowGotoBoard(true);
    handleFileUpload({ folder_id, response, project });

    const projectWithoutFiles = { ...project, files: [] };
    setData({
      ...data,
      projects: [...data.projects, projectWithoutFiles],
    });
    setSelectedProject(projectWithoutFiles);
    handleTasksInProjectIBelongTo({
      projectId: project._id,
      selectedProject: projectWithoutFiles,
    });
    setShowProjectModal(false);
    setShowProjectDetailsModal(true);
  };

  const handleFileUpload = async ({ folder_id, response, project }) => {
    console.log("handle file upload", { folder_id, response, project });
    if (project.files && project.files.length) {
      let filesToUpload = {};

      for (const file of project.files) {
        const id = uuidv4();

        // console.log(file);
        filesToUpload = {
          ...filesToUpload,
          [id]: {
            id,
            ...cloneDeep(file),
            progress: 0,
            cancel: 0,
            status: 0,
            close: 0,
            stage: 0,
            projectId: project._id,
            folder_id: project.project_folder_id,
            error: 0,
            token,
          },
        };
      }
      console.log(filesToUpload);
      setUploadFile({ ...filesToUpload });
      return;
    }
  };

  const taskCreated = ({ task, updatedProject }) => {
    setShowTaskModal(false);
    const foundListIndex = list.findIndex(
      (list) => list.status === task.status
    );
    if (foundListIndex !== -1) {
      const tasks = list[foundListIndex].tasks;
      list[foundListIndex].tasks = [task, ...tasks];
      setList([...list]);
    }

    const projects = data.projects;
    const foundProjectIndex = projects.findIndex(
      (project) => project._id === updatedProject._id
    );
    if (foundProjectIndex !== -1) {
      const project = projects[foundProjectIndex];
      projects[foundProjectIndex] = {
        ...project,
        ...updatedProject,
        team: updatedProject.team,
      };
      setData({
        ...data,
        projects,
      });
      setSelectedProject({
        ...project,
        ...updatedProject,
        team: updatedProject.team,
      });
    }
  };

  const taskUpdated = (response) => {
    const { task, updatedProject, isTaskModalOpen } = response;
    setShowTaskEditModal(isTaskModalOpen ? isTaskModalOpen : false);
    const foundListIndex = list.findIndex(
      (list) => list.status === task.status
    );
    if (foundListIndex !== -1) {
      const tasks = list[foundListIndex].tasks;
      const foundTaskIndex = tasks.findIndex(
        (taskEl) => taskEl._id === task._id
      );
      if (foundTaskIndex !== -1) {
        let foundTask = tasks[foundTaskIndex];
        tasks[foundTaskIndex] = {
          ...task,
          unreadCount: foundTask.unreadCount,
          commentsCount: foundTask.commentsCount,
        };
      }
      list[foundListIndex].tasks = tasks;
      setList([...list]);
      setSelectedTask({
        ...task,
      });
    }

    // Task may contain new members we will need to add in project team
    if (updatedProject) {
      const projects = data.projects;
      const foundProjectIndex = projects.findIndex(
        (project) => project._id === updatedProject._id
      );
      if (foundProjectIndex !== -1) {
        const project = projects[foundProjectIndex];
        projects[foundProjectIndex] = {
          ...project,
          team: updatedProject.team,
        };
        setData({
          ...data,
          projects,
        });
        setSelectedProject({
          ...project,
          team: updatedProject.team,
        });
      }
    }
  };

  const projectUpdated = (response) => {
    const updatedProject = { ...response.project };
    const projects = cloneDeep(data.projects);
    const foundProjectIndex = projects.findIndex(
      (project) => project._id === updatedProject._id
    );
    if (foundProjectIndex !== -1) {
      const project = projects[foundProjectIndex];
      projects[foundProjectIndex] = {
        ...updatedProject,
        files: project.files,
      };
      setData({
        ...data,
        projects,
      });
      setSelectedProject({
        ...projects[foundProjectIndex],
      });
    }

    setShowProjectEditModal(
      response?.isProjectModalOpen ? response?.isProjectModalOpen : false
    );
  };

  const updateProjectList = (response) => {
    const updatedProject = response.project;
    const projects = data.projects;
    const foundProjectIndex = projects.findIndex(
      (project) => project._id === updatedProject._id
    );
    if (foundProjectIndex !== -1) {
      // const project = projects[foundProjectIndex];
      projects[foundProjectIndex].list = updatedProject.list;
      setData({
        ...data,
        projects,
      });
      setSelectedProject({
        ...selectedProject,
        list: [
          ...selectedProject.list,
          updatedProject.list[updatedProject.list.length - 1],
        ],
      });
      setList([
        ...list,
        {
          ...updatedProject.list[updatedProject.list.length - 1],
          tasks: [],
        },
      ]);
    }
  };

  const countTasks = (list = []) => {
    if (list?.length == 0) return 0;
    return list
      .map((list) => {
        if (list?.tasks) return list.tasks.length;
        return 0;
      })
      .reduce((a, c, i, arr) => a + c);
  };

  const countCompleted = (list = []) => {
    if (list.length == 0) return 0;
    const completedList = list.filter((list) => list.status === "COMPLETE");
    return !isEmpty(completedList) ? completedList[0]?.tasks.length : 0;
  };

  const countTask = (list = [], by = "") => {
    if (list.length == 0) return 0;
    const completedList = list.filter((list) => list.status === by);
    return !isEmpty(completedList) ? completedList[0]?.tasks.length : 0;
  };

  const handleDeleteTask = async ({ task }) => {
    try {
      if (
        !(await DialogModal({
          title: "Delete Task",
          description: `Are you sure you want to delete <b>${task.taskName}</b> ?`,
          type: "warning",
        }))
      ) {
        return;
      }
      const response = await deleteTask(token, task._id);
      if (response && response?.success) {
        const foundListIndex = list.findIndex(
          (list) => list.status === task.status
        );
        if (foundListIndex !== -1) {
          const tasks = list[foundListIndex].tasks.filter(
            (taskEl) => taskEl._id !== task._id
          );

          list[foundListIndex].tasks = tasks;
          setList([...list]);
        }

        showToast({
          message: {
            title: `${task.taskName} deleted successfully...`,
          },
          type: "success",
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      showToast({
        message: {
          title: "Unable to Delete Task",
        },
        type: "error",
      });
      console.error(error);
    }
  };

  const setTaskUrl = ({ project, _id }) => {};

  const handleEditTask = ({ task }) => {
    setSelectedTask({
      ...task,
    });
    setSelectedList({
      ...list.find((list) => list.status === task.status),
    });
    setShowTaskEditModal(true);
  };

  const handleDeleteList = async (listToDelete) => {
    try {
      if (
        !(await DialogModal({
          title: "Delete List",
          description: `Are you sure you want to delete <b>${listToDelete.title}</b> ?`,
          type: "warning",
        }))
      ) {
        return;
      }

      const response = await deleteList({
        token,
        list: listToDelete,
        projectId: selectedProject._id,
      });
      if (response.success) {
        showToast({
          message: {
            title: "List Deleted",
          },
          type: "success",
        });
        const projects = data.projects;
        const foundProjectIndex = projects.findIndex(
          (project) => project._id === selectedProject._id
        );
        if (foundProjectIndex !== -1) {
          projects[foundProjectIndex].list = [
            ...projects[foundProjectIndex].list.filter(
              (listEl) => listEl._id !== listToDelete._id
            ),
          ];
          setData({
            ...data,
            projects,
          });
          setList(list.filter((listEl) => listEl._id !== listToDelete._id));
          setSelectedProject({
            ...selectedProject,
            list: [
              ...selectedProject.list.filter(
                (listEl) => listEl._id !== listToDelete._id
              ),
            ],
          });
        }
      } else {
        throw new Error();
      }
    } catch (err) {
      console.log(err);
      showToast({
        message: {
          title: "Unable to delete list",
        },
        type: "error",
      });
    }
  };

  const handleUpdatedList = async ({ listToUpdate, project }) => {
    // console.log(listToUpdate);
    setSelectedProject({
      ...selectedProject,
      list: project.list,
    });

    const projects = data.projects;
    const foundProjectIndex = projects.findIndex(
      (project) => project._id === selectedProject._id
    );
    if (foundProjectIndex !== -1) {
      projects[foundProjectIndex].list = project.list;
      setData({
        ...data,
        projects,
      });
    }

    const foundListIndex = list.findIndex(
      (list) => list._id === listToUpdate._id
    );
    if (foundListIndex !== -1) {
      const foundList = list[foundListIndex];
      foundList.tasks.forEach((task) => {
        task.status = listToUpdate.status;
        task.title = listToUpdate.title;
      });

      list[foundListIndex] = {
        ...listToUpdate,
        tasks: foundList.tasks,
      };
      // console.log(list);
      setList([...list]);
    }
  };

  const showColorPicker = ({ e, checkedColor, type }) => {
    let x = e.clientX,
      y = e.clientY,
      sx = e.screenX,
      sy = e.screenY,
      w = window.innerWidth,
      h = window.innerHeight,
      // task2ChatStyle.bottom = sy - y + 45;
      top = y,
      // If screen left is < 300 Else if screen left
      // left = x < 150 ? 150 : w - x < 150 ? x - 175 : x;
      left = x;

    if (h - y < 180) {
      top = y - 180;
    }
    if (w - x < 250) {
      left = x - 250;
    }

    setColors([...(type === "task" ? lightTaskColors : darkTaskColors)]);
    setColorPickerState({
      ...colorPickerState,
      showColorPicker: true,
      colorPickerStyle: {
        top,
        left,
      },
      checkedColor,
      type,
    });
  };

  const handleColorPicker = (color) => {
    setColorPickerState({
      ...colorPickerState,
      checkedColor: color,
    });
    if (colorPickerState.type === "list") {
      socket.emit(
        "fx:update-list",
        {
          projectId: selectedProject._id,
          list: {
            _id: selectedList._id,
            color,
            title: selectedList.title,
          },
        },
        (response) => {
          if (response.success) {
            const foundListIndex = list.findIndex(
              (list) => list._id === selectedList._id
            );
            if (foundListIndex !== -1) {
              list[foundListIndex].color = color;
              setList([...list]);
            }
          }
        }
      );
    } else if (colorPickerState.type === "task") {
      socket.emit(
        "fx:save-color-settings",
        {
          task_id: selectedTask._id,
          color,
          textColor: "",
        },
        (response) => {
          const foundListIndex = list.findIndex(
            (list) => list.status === selectedTask.status
          );
          if (foundListIndex !== -1) {
            const tasks = list[foundListIndex].tasks;
            const foundTaskIndex = tasks.findIndex(
              (taskEl) => taskEl._id === selectedTask._id
            );
            if (foundTaskIndex !== -1) {
              tasks[foundTaskIndex] = {
                ...tasks[foundTaskIndex],
                color,
              };
            }
            list[foundListIndex].tasks = tasks;
            setList([...list]);
          }
        }
      );
    }
  };

  const handleClearUnread = ({ notificationsCount }) => {
    const foundListIndex = list.findIndex(
      (list) => list.status === selectedTask.status
    );
    if (foundListIndex !== -1) {
      const tasks = list[foundListIndex].tasks;
      const foundTaskIndex = tasks.findIndex(
        (taskEl) => taskEl._id === selectedTask._id
      );
      if (foundTaskIndex !== -1) {
        tasks[foundTaskIndex] = {
          ...tasks[foundTaskIndex],
          unreadCount: 0,
        };
      }
      list[foundListIndex].tasks = tasks;
      setList([...list]);
    }

    // set notifications count
    populateNotifications(notificationsCount, "notification");
  };

  const updateTaskUi = ({ taskToUpdate, newData }) => {
    let list = _list.current;
    const foundListIndex = list.findIndex(
      (list) => list.status === taskToUpdate.status
    );
    if (foundListIndex !== -1) {
      const tasks = list[foundListIndex].tasks;
      const foundTaskIndex = tasks.findIndex(
        (taskEl) => taskEl._id === taskToUpdate._id
      );

      if (foundTaskIndex !== -1) {
        tasks[foundTaskIndex] = {
          ...tasks[foundTaskIndex],
          ...newData,
        };

        list[foundListIndex].tasks = tasks;
        setList([...list]);
      }
    }
  };

  const moveTaskToList = ({
    taskToMove,
    oldStatus,
    newStatus,
    saveToServer = false,
  }) => {
    // console.log(taskToMove, oldStatus, newStatus);
    let list = _list.current;
    /*const foundListSourceIndex = list.findIndex(
      (listEl) => listEl.status === oldStatus
    );
    */
    const foundListDestinationIndex = list.findIndex(
      (listEl) => listEl.status === newStatus
    );

    /*list[foundListSourceIndex].tasks = list[foundListSourceIndex].tasks.filter(
      (task) => task._id !== taskToMove._id
    );
    */

    list = list.map((listEl) => {
      return {
        ...listEl,
        tasks: listEl.tasks.filter((task) => task._id !== taskToMove._id),
      };
    });
    list[foundListDestinationIndex].tasks.splice(0, 0, taskToMove);
    setList([...list]);

    if (selectedTask._id === taskToMove._id) {
      setSelectedTask({
        ...selectedTask,
        status: newStatus,
      });
      setSelectedList({
        ...list.find((list) => list.status === newStatus),
      });
    }

    if (saveToServer) {
      socket.emit(
        "fx:task-status-updated",
        {
          taskId: taskToMove._id,
          status: newStatus,
          oldStatus,
          token,
        },
        (response) => {
          if (response && response.success) {
          }
        }
      );
    }
  };

  const handleAddProjectMembers = () => {
    setProjectEditStage(3);
    setShowProjectEditModal(true);
  };

  const calculateProjectProgress = () => {
    let tasks = list.map((listEl) => listEl.tasks).flat();
    // console.log("total tasks", tasks.length);

    let projectProgress = tasks?.length
      ? tasks?.reduce((acc, cur) => {
          if (cur?.status === "AWAITING") {
            acc += 0;
          } else if (cur?.status === "INCOMPLETE") {
            acc += 25;
          } else if (cur?.status === "UNDER_REVIEW") {
            acc += 50;
          } else if (cur?.status === "COMPLETE") {
            acc += 100;
          }
          return acc;
        }, 0)
      : 0;

    const numberOfTasks = tasks?.length || 0;
    projectProgress = (projectProgress / (numberOfTasks * 100)) * 100;
    projectProgress = projectProgress || 0;
    projectProgress =
      typeof projectProgress === "number" && projectProgress !== 0
        ? Math.floor(projectProgress)
        : projectProgress;

    return projectProgress;
  };

  const handlePinProject = (status) => {
    try {
      let project = selectedProject;
      let projectId = project?._id;
      const payload = { projectId, token, status };
      socket.emit("fx:pin-project", payload, (response) => {
        if (response && response.success) {
          setPinnedProject(status === "pin" ? projectId : "");
          showToast({
            message: {
              title:
                status === "pin"
                  ? "Project pinned to Board"
                  : "Unpinned Project",
            },
            type: "success",
          });
        }
      });
    } catch (err) {
      console.log("Handle pinned task error:", err);
    }
  };

  const handleDragEnd = (e) => {
    // console.log(list);
    if (!e.destination) {
      return;
    }
    const { destination, source, draggableId, type } = e;
    const indexFrom = source?.index;
    const indexTo = destination?.index;

    if (type === "lists") {
      let mutatedList = arrayMove([...list], indexFrom, indexTo);
      setList([...mutatedList]);
      socket.emit("fx:save-list-arrangement", {
        arrangement: [
          ...mutatedList.map((listEl, i) => {
            return {
              position: i,
              status: listEl.status,
            };
          }),
        ],
        user_id: user._id,
        projectId: selectedProject._id,
      });
    } else if (type === "tasks") {
      if (source.droppableId === destination.droppableId) {
        const foundListIndex = list.findIndex(
          (listEl) => listEl.status === destination.droppableId
        );

        const listToMutate = list[foundListIndex];
        const mutatedTask = arrayMove(
          [...listToMutate.tasks],
          indexFrom,
          indexTo
        );
        list[foundListIndex] = {
          ...listToMutate,
          tasks: [...mutatedTask],
        };
        setList([...list]);

        socket.emit("fx:save-task-status-arrangement", {
          taskStatusArrangement: [
            ...mutatedTask.map((task, i) => {
              return {
                project_id: task.project,
                task_id: task._id,
                task_position: i,
                status: destination.droppableId,
              };
            }),
          ],
          user_id: user._id,
          project_id: selectedProject._id,
          sourceStatus: destination.droppableId,
          destinationStatus: source.droppableId,
        });
      } else {
        const foundListDestinationIndex = list.findIndex(
          (listEl) => listEl.status === destination.droppableId
        );
        const foundListSourceIndex = list.findIndex(
          (listEl) => listEl.status === source.droppableId
        );

        const destinationTasks = list[foundListDestinationIndex].tasks;
        const sourceTasks = list[foundListSourceIndex].tasks;
        const taskToMove = sourceTasks[indexFrom];
        taskToMove.status = destination.droppableId;

        // console.log(isProjectLeaderOrCreatedBy(selectedProject), taskToMove);

        if (
          isProjectLeaderOrCreatedBy(selectedProject) ||
          taskToMove.assignedTo.includes(user._id) ||
          taskToMove.createdBy === user._id ||
          taskToMove.assignedTo.find((userEl) => userEl._id === user._id)
        ) {
          list[foundListDestinationIndex].tasks.splice(indexTo, 0, taskToMove);
          list[foundListSourceIndex].tasks = sourceTasks.filter(
            (task) => task._id !== sourceTasks[indexFrom]._id
          );
          setList([...list]);

          socket.emit(
            "fx:task-status-updated",
            {
              taskId: draggableId,
              status: destination.droppableId,
              oldStatus: source.droppableId,
              token,
            },
            (response) => {
              if (response && response.success) {
                // setData(combineData(data, { tasks }));
              }
            }
          );
          socket.emit("fx:save-task-status-arrangement", {
            taskStatusArrangement: [
              ...list[foundListDestinationIndex].tasks.map((task, i) => {
                return {
                  project_id: task.project,
                  task_id: task._id,
                  task_position: i,
                  status: destination.droppableId,
                };
              }),
              ...list[foundListSourceIndex].tasks.map((task, i) => {
                return {
                  project_id: task.project,
                  task_id: task._id,
                  task_position: i,
                  status: destination.droppableId,
                };
              }),
            ],
            user_id: user._id,
            project_id: selectedProject._id,
            status: destination.droppableId,
            sourceStatus: source.droppableId,
            destinationStatus: destination.droppableId,
          });
        } else {
          showToast({
            message: {
              title: "Unauthorized",
              description:
                "Only project leaders and assigned members can move a task",
            },
            type: "error",
          });
        }
      }
    }
  };

  const setAsCover = (file, task) => {
    let selectedActiveTask = task ? task : selectedTask;
    socket.emit(
      "fx:task-cover-image",
      {
        taskId: selectedActiveTask._id,
        cover: file,
      },
      (response) => {
        if (response && response.success) {
          console.log(response);

          const foundListIndex = list.findIndex(
            (list) => list.status === selectedActiveTask.status
          );
          if (foundListIndex !== -1) {
            const tasks = list[foundListIndex].tasks;
            const foundTaskIndex = tasks.findIndex(
              (taskEl) => taskEl._id === selectedActiveTask._id
            );
            if (foundTaskIndex !== -1) {
              tasks[foundTaskIndex] = {
                ...tasks[foundTaskIndex],
                cover: file,
              };
            }
            list[foundListIndex].tasks = tasks;
            setList([...list]);
            showToast({
              message: {
                title: file ? "Cover set" : "Cover removed",
              },
              type: "success",
            });
          }
        }
      }
    );
  };

  const isProjectLeaderOrCreatedBy = (selectedProject) => {
    //  is  Company Admin or SuperAdmin
    const isAdminCompanyUser =
      (user.isAdmin || user.isSuperAdmin) && user?.account_type === 2;

    const leaders =
      selectedProject && selectedProject.projectLeader
        ? selectedProject.projectLeader
        : [];
    return (
      leaders.find((leader) => leader._id === user._id) ||
      selectedProject.createdBy === user._id ||
      selectedProject.createdBy?._id === user._id ||
      isAdminCompanyUser
    );
  };

  const getSharableLink = () => {
    const projectId = selectedProject._id;
    socket.emit(
      "get-sharable-link",
      {
        projectId,
        enabled: true,
      },
      async (response) => {
        if (response && response.success) {
          // console.log("share", response);
          const {
            sharable: { id },
          } = response;
          CopyToClipboard({
            text: `${process.env.REACT_APP_FRONTEND_URL}/project-invite/${id}/${projectId}`,
            successText: "Link copied",
            successDescription:
              "Anyone with the link can request to join this project",
          });
        }
      }
    );
  };

  const toggleProjectShare = () => {
    const projects = cloneDeep(data.projects);
    const projectId = selectedProject._id;

    socket.emit(
      "get-sharable-link",
      {
        projectId,
        enabled: !selectedProject?.sharable?.enabled,
      },
      async (response) => {
        if (response && response.success) {
          console.log(response);
          setSelectedProject({
            ...selectedProject,
            sharable: response.sharable,
          });

          const foundProjectIndex = projects.findIndex(
            (project) => project._id === selectedProject._id
          );
          if (foundProjectIndex !== -1) {
            projects[foundProjectIndex].sharable = response.sharable;
            setData({
              ...data,
              projects,
            });
          }
        }
      }
    );
  };

  const searchBoard = (searchString) => {
    setHasSearched(false);
    socket.emit(
      "fx:task-board-search",
      {
        searchString,
        userId: user._id,
      },
      (response) => {
        if (response?.success) {
          console.log(response);
          setHasSearched(true);
          const { projects, tasks } = response.data;
          setBoardSearchResults({
            projects,
            tasks,
          });
        }
      }
    );
  };

  const setShowBoardSettings = (value) => {
    eventBus.dispatch("setShowBoardSettings", value);
  };

  const removeCoverImages = ({ filesToRemove }) => {
    const filesIds = filesToRemove.map((file) => {
      return file.cloud_id;
    });
    let modifiedList = cloneDeep(list);

    modifiedList = modifiedList.map((list) => {
      return {
        ...list,
        tasks: list.tasks.map((task) => {
          return {
            ...task,
            cover: filesIds.includes(task?.cover?.cloud_id) ? null : task.cover,
          };
        }),
      };
    });

    setList(modifiedList);
  };

  eventBus.useCustomEventListener("remove-cover-image", removeCoverImages);
  eventBus.useCustomEventListener("showBoardLoader", (val) =>
    setIsLoading(val)
  );

  // eventBus.useCustomEventListener('moveTaskToList', moveTaskToList)

  const setHasPersonalised = (userId) => {
    setPersonalised();
    return new Promise((resolve, reject) => {
      socket.emit("eb:has-personalised", userId, (err, data) => {
        if (err) {
          reject(err);
        } else {
          resolve({});
        }
      });
    });
  };

  const openGroup = (groupId) => {
    eventBus.dispatch("open-group", groupId);
  };

  const handleSelectUser = (user) => {
    if (user?.username)
      window.open(
        `${process.env.REACT_APP_MESSENGER_FRONTEND}/profile/${user.username}`,
        "_blank"
      );
  };

  return (
    <section
      className={`board-section ${hidePage ? "d-none" : ""}`}
      style={{
        ...(boardSettings.mode === "colors"
          ? {
              background: boardSettings.color,
            }
          : boardSettings.mode === "photos"
          ? {
              backgroundImage: `url(${bgImages[boardSettings.currentPhoto]})`,
              backgroundSize: "cover",
            }
          : boardSettings.mode === "custom-photo" && user._id
          ? {
              backgroundImage: `url(${URLS.backendBoardSettings}/custom-photo/${user._id}?i=${boardSettings.unique})`,
              backgroundSize: "cover",
            }
          : {}),
        height: window.innerHeight,
      }}
    >
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            zIndex: "1000",
          }}
        >
          <Spinner spinnerHeight={`2rem`} />
        </div>
      )}
      <Topbar usage="board" />

      <main>
        <div className="nav-continued position-absolute w-100"></div>

        {styleHelper.isMobile ? (
          <div
            className={`project-slider ${
              showToolbarDetails ? "show" : "hide"
            } position-absolute d-block d-md-none`}
          >
            <MobileToolbar
              selectedProject={selectedProject}
              pinnedProject={pinnedProject}
              handlePinProject={handlePinProject}
              setShowProjectDetailsModal={setShowProjectDetailsModal}
              showProjectDetailsModal={showProjectDetailsModal}
              data={data}
              handleSelectProject={handleSelectProject}
              list={list}
              countTasks={countTasks}
              countCompleted={countCompleted}
              handleAddProjectMembers={handleAddProjectMembers}
              isProjectLeaderOrCreatedBy={isProjectLeaderOrCreatedBy}
              setShowToolbarDetails={setShowToolbarDetails}
              calculateProjectProgress={calculateProjectProgress}
              getSharableLink={getSharableLink}
              toggleProjectShare={toggleProjectShare}
              setShowBoardSettings={setShowBoardSettings}
              allProjectMembers={allProjectMembers}
            />

            <div
              onClick={() => setShowToolbarDetails(false)}
              className={`overlay ${showToolbarDetails ? "d-block" : "d-none"}`}
            ></div>
          </div>
        ) : null}

        <div
          className={`toolbar d-flex align-items-center bg-white ${
            showBoardSearch ? "show-search" : "hide-search"
          }`}
        >
          <div className="actions d-flex flex-grow-0 justify-content-between border-right">
            <div className="d-flex d-md-block align-items-center">
              <TextLongIcon
                className="d-md-none"
                onClick={() => setShowToolbarDetails(true)}
              />
              <span
                className="position-relative"
                id="select-holder"
                onClick={() => {
                  if (!styleHelper.isMobile) {
                    setShowProjectSelector(!showProjectSelector);
                  }
                }}
                title={selectedProject.name}
              >
                <select
                  className="project-select p-cursor"
                  readOnly={true}
                  onChange={(e) => handleSelectProject(e.target.value)}
                  value={selectedProject?._id}
                  style={!styleHelper.isMobile ? { pointerEvents: "none" } : {}}
                >
                  {data?.projects?.map((project) => (
                    <option key={project._id} value={project._id}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </span>
              <p className="date-created m-0">
                Created at{" "}
                {selectedProject?.createdAt
                  ? format(new Date(selectedProject?.createdAt), "MMM dd, yy")
                  : "..."}
              </p>
            </div>

            <div className="buttons">
              <button
                className="btn button-light-custom hide-on-mobile"
                title={
                  pinnedProject === selectedProject._id
                    ? "Unpin project"
                    : "Pin project"
                }
                onClick={() =>
                  handlePinProject(
                    pinnedProject === selectedProject._id ? "" : "pin"
                  )
                }
              >
                {pinnedProject === selectedProject._id ? (
                  <PinOffIcon />
                ) : (
                  <PinIcon />
                )}
              </button>

              <Dropdown
                show={!hideProjectDropdown}
                ref={projectDropdownRef}
                alignRight
              >
                <Dropdown.Toggle
                  bsPrefix={`btn button-light-custom toggle hide-on-mobile ${
                    !hideProjectDropdown ? "active" : ""
                  }`}
                  title="Pipeline Details"
                  onClick={() => setHideProjectDropdown(false)}
                >
                  <TextLongIcon />
                </Dropdown.Toggle>

                <Dropdown.Menu bsPrefix={`menu p-0 rounded`} alignRight>
                  <Dropdown.Item
                    as="button"
                    onClick={
                      () => {
                        setProjectEditStage(1);
                        setShowProjectEditModal(true);
                      }
                      // setShowProjectDetailsModal(!showProjectDetailsModal)
                    }
                  >
                    <span className="py-2">
                      <EyeIcon /> Edit Details{" "}
                    </span>
                  </Dropdown.Item>

                  <Dropdown.Item
                    as="a"
                    href={`/project-performance/${selectedProject._id}`}
                  >
                    <span className="text-dark py-2">
                      <ChartLineIcon /> Performance
                    </span>
                  </Dropdown.Item>

                  {selectedProject.groupId && (
                    <Dropdown.Item
                      as="button"
                      onClick={() => openGroup(selectedProject.groupId)}
                    >
                      <span className="text-dark py-2">
                        <ForumOutlineIcon /> Channel
                      </span>
                    </Dropdown.Item>
                  )}

                  {isProjectLeaderOrCreatedBy(selectedProject) && (
                    <Dropdown.Item as="div">
                      <div className="w-100 d-flex justify-content-between align-items-center p-cursor">
                        <span>
                          <LinkVariantIcon />
                          <span>Enable Sharable Link </span>
                        </span>

                        <label
                          class="switch mt-2"
                          title={
                            selectedProject?.sharable?.enabled
                              ? "Disable link"
                              : "Enable link"
                          }
                        >
                          <input
                            type="checkbox"
                            onChange={() => toggleProjectShare()}
                            checked={selectedProject?.sharable?.enabled}
                          />
                          <span class="slider round"></span>
                        </label>
                      </div>
                    </Dropdown.Item>
                  )}
                  {isProjectLeaderOrCreatedBy(selectedProject) &&
                  selectedProject?.sharable?.enabled ? (
                    <Dropdown.Item
                      as="div"
                      bsPrefix={`copy no-hover d-flex justify-content-end align-items-end px-3 pb-3`}
                    >
                      <button
                        className="btn btn-outline-primary d-flex mt-1"
                        id="copy-link-btn"
                        style={{
                          width: "13.1875rem",
                          height: "2.25rem",
                          fontWeight: 600,
                          lineHeight: "1rem",
                        }}
                        onClick={() => {
                          getSharableLink();
                        }}
                        disabled={!selectedProject?.sharable?.enabled}
                      >
                        <CopyLinkIcon />
                        <span className="ml-2">Copy Pipelink Link</span>
                      </button>
                    </Dropdown.Item>
                  ) : null}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown alignRight>
                <Dropdown.Toggle
                  id="dropdown-basic"
                  bsPrefix={`btn btn-outline-custom-primary mr-0`}
                >
                  <PlusIcon /> <span className="hide-on-mobile">New</span>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  bsPrefix={`menu project-dropdown-menu rounded`}
                  alignRight
                >
                  <Dropdown.Item
                    as="button"
                    onClick={() => setShowProjectModal(true)}
                  >
                    <ClipboardIcon /> Pipeline
                  </Dropdown.Item>
                  {/*<Dropdown.Item as="button">
                    <FileIcon /> Task
              </Dropdown.Item>*/}

                  {isProjectLeaderOrCreatedBy(selectedProject) && (
                    <Dropdown.Item
                      as="button"
                      onClick={() => setShowAddButton(false)}
                    >
                      <svg
                        width="14"
                        height="18"
                        viewBox="0 0 14 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M11.7874 0.663431C12.2625 0.663373 12.72 0.843677 13.0672 1.16791C13.4145 1.49213 13.6257 1.93611 13.6583 2.4101L13.6624 2.53843V15.4534C13.6625 15.9285 13.4822 16.3859 13.158 16.7332C12.8337 17.0805 12.3898 17.2917 11.9158 17.3243L11.7874 17.3284H2.2041C1.729 17.3285 1.27158 17.1482 0.924307 16.824C0.577031 16.4997 0.365787 16.0558 0.333269 15.5818L0.329102 15.4534V2.53843C0.328832 2.06318 0.509043 1.60556 0.833292 1.25811C1.15754 0.910653 1.60163 0.699292 2.07577 0.666764L2.2041 0.662598H11.7874V0.663431ZM4.08327 12.3334C3.75175 12.3334 3.43381 12.4651 3.19938 12.6995C2.96496 12.934 2.83327 13.2519 2.83327 13.5834C2.83327 13.915 2.96496 14.2329 3.19938 14.4673C3.43381 14.7017 3.75175 14.8334 4.08327 14.8334H9.9166C10.2481 14.8334 10.5661 14.7017 10.8005 14.4673C11.0349 14.2329 11.1666 13.915 11.1666 13.5834C11.1666 13.2519 11.0349 12.934 10.8005 12.6995C10.5661 12.4651 10.2481 12.3334 9.9166 12.3334H4.08327Z"
                          fill="#788898"
                        />
                      </svg>
                      List
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {/* <div className="members border-right d-flex flex-grow-1 justify-content-center align-items-center hide-on-mobile">
            <div className="d-flex align-items-center">
              <span className="desc mr-2">Team</span>
              <div className="d-flex">
                {allProjectMembers(selectedProject, {
                  includeInvited: true,
                })
                  ? allProjectMembers(selectedProject, {
                      includeInvited: true,
                    })
                      .slice(0, 4)
                      .map((user, index) => (
                        <Avatar
                          key={index}
                          width={40}
                          height={40}
                          user={user}
                          zIndex={100 - index}
                          // onClick={() => handleSelectUser(user)}
                        />
                      ))
                  : null}
                {allProjectMembers(selectedProject, {
                  includeInvited: true,
                }) &&
                allProjectMembers(selectedProject, {
                  includeInvited: true,
                }).length > 4 ? (
                  <Avatar
                    width={40}
                    height={40}
                    isCounter={true}
                    count={
                      allProjectMembers(selectedProject, {
                        includeInvited: true,
                      }).length - 4
                    }
                    zIndex={1}
                    usersList={allProjectMembers(selectedProject, {
                      includeInvited: true,
                      separate: true,
                    })}
                    showUsersList={showUsersList}
                  />
                ) : null}
                {isProjectLeaderOrCreatedBy(selectedProject) ? (
                  <AddUserIcon
                    className="p-cursor"
                    onClick={() => handleAddProjectMembers()}
                  />
                ) : null}
              </div>
            </div>
          </div> */}

          <div className="progress-wrapper flex-grow-1">
            <div className="project-progress d-flex border-right hide-on-mobile">
              <div className="d-flex flex-column justify-content-between py-2">
                <div className="d-flex font-weight-bold justify-content-between">
                  <span>Progress</span>
                  <span style={{ color: "#0ACCA9" }}>
                    {calculateProjectProgress()}%
                  </span>
                </div>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${calculateProjectProgress()}%` }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>

              <div className="content d-flex flex-column justify-content-between py-2 pb-1">
                <span>
                  <CheckCircleIcon />
                  <span className="ml-1">
                    {countCompleted(list)} Tasks Completed
                  </span>
                </span>
                <span>
                  <FileDocumentIcon />
                  <span className="ml-1">
                    {countTask(list, "INCOMPLETE") +
                      countTask(list, "UNDER_REVIEW")}{" "}
                    Tasks Opened
                  </span>
                </span>
              </div>
            </div>

            <div className="settings" id="desktop-settings">
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setSearchString(searchString);
                }}
              >
                <div
                  className="btn button-light-custom d-none d-md-flex label justify-content-start postion-relative"
                  title="Board Settings"
                >
                  <button
                    type="button"
                    className="btn position-absolute search-icon-btn"
                    style={{ marginLeft: "-0.4rem" }}
                    onClick={() => setShowBoardSearch(true)}
                  >
                    <SearchIcon className="" />
                  </button>
                  <input
                    className="px-2 text"
                    value={searchString}
                    ref={searchStringRef}
                    onChange={(e) => setSearchString(e.target.value)}
                  />
                  <button
                    type="button"
                    className="btn px-2"
                    onClick={() => {
                      setShowBoardSearch(true);
                      setSearchString("");
                      setBoardSearchResults(initialBoardSearchResults);
                    }}
                  >
                    ✖
                  </button>
                </div>
              </form>

              <button
                className="btn button-light-custom"
                title="Board Settings"
                onClick={() => setShowBoardSettings(false)}
              >
                <CogIcon />
              </button>
            </div>
          </div>
        </div>

        <div
          ref={boardSearchResultsRef}
          id={`search-results`}
          className={`position-fixed ${searchString ? "d-block" : "d-none"}`}
        >
          {searchString &&
          boardSearchResults.projects.length &&
          boardSearchResults.tasks.length ? (
            <p className="info-text">
              Search result of <span>“{searchString}”..</span>
            </p>
          ) : null}

          {!searchString && !hasSearched ? (
            <p className="info-text mb-0">Search...</p>
          ) : null}

          <div className="all-search-holder overflow-auto">
            {boardSearchResults.projects.length ? (
              <div className="projects">
                <p className="title">Projects</p>

                {boardSearchResults.projects.map((project) => (
                  <div
                    key={project._id}
                    className="item d-flex w-100 p-cursor"
                    onClick={() => {
                      setShowBoardSearch(false);
                      handleSelectProject(project._id);
                    }}
                  >
                    <div className="image-holder d-flex align-items-center justify-content-center">
                      <ProjectIcon style={{ transform: "scale(2)" }} />
                    </div>

                    <div className="content">
                      <p className="project-title">{project.name}</p>
                      {project.createdAt ? (
                        <p className="created-at">
                          Created at{" "}
                          {format(
                            new Date(project?.createdAt),
                            "MMM dd',' yyyy"
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            {boardSearchResults.tasks.length ? (
              <div className="tasks">
                <p className="title">Tasks</p>

                {boardSearchResults.tasks.map((task) => (
                  <div
                    key={task._id}
                    onClick={() => {
                      setShowBoardSearch(false);
                      loadTask({
                        taskId: task._id,
                        projectId: task.project._id,
                        data: { ...data },
                      });
                    }}
                  >
                    {task.cover && Object.keys(task.cover).length ? (
                      <div className="item d-flex w-100">
                        <div className="image-holder overflow-hidden">
                          <img
                            src={`${watcherServerUrl}/files/${task.cover.cloud_id}/${task.cover.originalName}.${task.cover.extension}?view`}
                            width="100%"
                            alt=""
                          />
                        </div>

                        <div className="content">
                          <p className="project-title">{task.taskName}</p>
                          <p className="created-at">
                            in “{task?.list?.title}” on “{task.project.name}”
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className="item d-flex w-100">
                        <div className="image-holder bg-white no-image">
                          <p className="holder-title m-0">
                            {task.taskName && task.taskName.length > 20
                              ? `${task.taskName.slice(0, 40)}...`
                              : task.taskName}
                          </p>
                          <p className="holder-content m-0">
                            {task.taskDetail && task.taskDetail.length > 20
                              ? `${task.taskDetail.slice(0, 40)}...`
                              : task.taskDetail}
                          </p>
                          {task.dueDate ? (
                            <button className="btn btn-t-date btn-outline-secondary">
                              <ClockOutlineIcon />{" "}
                              <span>
                                {formatSlashedDate(task.dueDate, false)}
                              </span>
                            </button>
                          ) : null}
                        </div>

                        <div className="content">
                          <p className="project-title">{task.taskName}</p>
                          <p className="created-at">
                            in “{task?.list?.title}” on “{task.project.name}”
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : null}
          </div>

          {hasSearched &&
          !boardSearchResults.projects.length &&
          !boardSearchResults.tasks.length ? (
            <div className="no-search-results text-center mt-1">
              <NoSearchResults />
              <p>
                Ooops,.. we couldn't find any customer or pipeline that matched
                your search.
              </p>
            </div>
          ) : null}
        </div>

        <ProjectSelector
          projects={data.projects}
          handleSelectProject={handleSelectProject}
          ProjectSelectorRef={ProjectSelectorRef}
          showProjectSelector={showProjectSelector}
          selectedProject={selectedProject}
        />

        <DragDropContext
          onDragEnd={(e) => {
            handleDragEnd(e);
          }}
        >
          <Droppable
            droppableId="all-columns"
            direction="horizontal"
            type="lists"
          >
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {list.map((listItem, index) => (
                  <Draggable
                    key={listItem.status}
                    index={index}
                    draggableId={listItem.status}
                  >
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <div
                          className="stage"
                          style={{ borderTop: `4px solid ${listItem.color}` }}
                        >
                          <div {...provided.dragHandleProps}>
                            <StageHeader
                              key={listItem._id}
                              user={user}
                              allList={list}
                              listItem={listItem}
                              isProjectLeaderOrCreatedBy={() =>
                                isProjectLeaderOrCreatedBy(selectedProject)
                              }
                              setSelectedList={setSelectedList}
                              setShowTaskModal={setShowTaskModal}
                              handleDeleteList={handleDeleteList}
                              selectedProject={selectedProject}
                              handleUpdatedList={handleUpdatedList}
                              token={token}
                              showColorPicker={showColorPicker}
                            />
                          </div>
                          <Droppable droppableId={listItem.status} type="tasks">
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="stage-body"
                              >
                                <>
                                  {listItem.tasks.map((task, index) => (
                                    <Draggable
                                      key={task._id}
                                      index={index}
                                      draggableId={task._id}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <React.Fragment key={task._id}>
                                            <TaskCard
                                              user={user}
                                              task={task}
                                              list={list}
                                              isProjectLeaderOrCreatedBy={() =>
                                                isProjectLeaderOrCreatedBy(
                                                  selectedProject
                                                )
                                              }
                                              handleDeleteTask={
                                                handleDeleteTask
                                              }
                                              handleEditTask={handleEditTask}
                                              setShowTaskDetailsModal={() =>
                                                setShowTaskDetailsModal(true)
                                              }
                                              setSelectedTask={() => {
                                                setTaskUrl({ ...task });
                                                setSelectedTask({ ...task });
                                              }}
                                              setSelectedList={() =>
                                                setSelectedList({ ...listItem })
                                              }
                                              showColorPicker={showColorPicker}
                                              showUsersList={showUsersList}
                                              setTaskEditStage={
                                                setTaskEditStage
                                              }
                                              toggleTaskNotifications={
                                                toggleTaskNotifications
                                              }
                                              moveTaskToList={moveTaskToList}
                                              setAsCover={setAsCover}
                                            />
                                          </React.Fragment>
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                </>

                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                          {
                            <div className="stage-footer">
                              {isProjectLeaderOrCreatedBy(selectedProject) ? (
                                <button
                                  className="btn"
                                  onClick={() => {
                                    setSelectedList(listItem);
                                    setShowTaskModal(true);
                                  }}
                                >
                                  {index == 0 ? (
                                    <>
                                      <PlusIcon /> Add Prospect
                                    </>
                                  ) : (
                                    <>
                                      <PlusIcon /> Add Customer
                                    </>
                                  )}
                                </button>
                              ) : (
                                <button className="btn">
                                  <span style={{ visibility: "hidden" }}>
                                    index == 0? (<PlusIcon /> Add Prospect) : (
                                    <PlusIcon /> Add Customer)
                                  </span>
                                </button>
                              )}
                            </div>
                          }
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}

                {provided.placeholder}

                {isProjectLeaderOrCreatedBy(selectedProject) && !isLoading ? (
                  <div className="add-new-list">
                    <AddNewList
                      token={token}
                      user={user}
                      list={list}
                      selectedProject={selectedProject}
                      updateProjectList={updateProjectList}
                      showAddButton={showAddButton}
                      setShowAddButton={setShowAddButton}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </main>

      {/* 
          Modals 
      */}
      {showProjectDetailsModal && (
        <ProjectDetailsModal
          isShared={isShared}
          canEdit={isProjectLeaderOrCreatedBy(selectedProject, user)}
          showProjectDetailsModal={showProjectDetailsModal}
          setShowProjectDetailsModal={(isOpen) =>
            setShowProjectDetailsModal(isOpen)
          }
          project={selectedProject}
          key={selectedProject._id}
          ongoingUploads={data.ongoingUploads}
          setProjectEditStage={setProjectEditStage}
          setShowProjectEditModal={(isOpen) => setShowProjectEditModal(isOpen)}
          handleFileUpload={(obj) => handleFileUpload(obj)}
          showGotoBoard={showGotoBoard}
          setShowGotoBoard={(isShow) => setShowGotoBoard}
          usersList={projectUsersList}
          showUsersList={showUsersList}
          usersListStyle={usersListStyle}
          allProjectMembers={allProjectMembers}
        />
      )}

      {showProjectModal && (
        <ProjectModal
          showProjectModal={showProjectModal}
          setShowProjectModal={(isOpen) => setShowProjectModal(isOpen)}
          clientList={data.clientList}
          teamsICreated={data.teamsICreated}
          employeesList={data.employeesList}
          handleProjectCreated={handleProjectCreated}
          allProjectMembers={allProjectMembers}
          setProjectEditStage={setProjectEditStage}
        />
      )}

      {showProjectEditModal && (
        <ProjectModal
          showProjectModal={showProjectEditModal}
          setShowProjectModal={(isOpen) => setShowProjectEditModal(isOpen)}
          clientList={data.clientList}
          teamsICreated={data.teamsICreated}
          employeesList={data.employeesList}
          mode={"edit"}
          selectedProject={selectedProject}
          projectEditStage={projectEditStage}
          projectUpdated={projectUpdated}
          setProjectEditStage={setProjectEditStage}
        />
      )}

      {showTaskModal && (
        <CustomerModal
          key={data.employeesList.length}
          user={user}
          showTaskModal={showTaskModal}
          setShowTaskModal={(isOpen) => setShowTaskModal(isOpen)}
          selectedList={selectedList}
          selectedProject={selectedProject}
          taskCreated={taskCreated}
          taskEditStage={taskEditStage}
          setTaskEditStage={setTaskEditStage}
        />
      )}

      {showTaskEditModal && selectedTask && (
        <CustomerModal
          key={data.employeesList.length}
          user={user}
          showTaskModal={showTaskEditModal}
          setShowTaskModal={(isOpen) => setShowTaskEditModal(isOpen)}
          selectedList={selectedList}
          selectedProject={selectedProject}
          selectedTask={selectedTask}
          taskCreated={taskCreated}
          taskUpdated={taskUpdated}
          taskEditStage={taskEditStage}
          setTaskEditStage={setTaskEditStage}
          mode={"edit"}
        />
      )}

      {showTaskDetailsModal && selectedTask && (
        <TaskDetailsModal
          key={selectedTask?._id}
          isShared={isShared}
          teamsIBelongTo={data.teamsIBelongTo}
          setAsCover={setAsCover}
          showTaskDetailsModal={showTaskDetailsModal}
          setShowTaskDetailsModal={(isOpen) => setShowTaskDetailsModal(isOpen)}
          selectedList={selectedList}
          selectedProject={selectedProject}
          selectedTask={selectedTask}
          handleClearUnread={handleClearUnread}
          showUsersList={showUsersList}
          projectUsersList={projectUsersList}
          list={list}
          moveTaskToList={moveTaskToList}
          setShowConversationForm={(state) => {
            setShowConversationForm(state);
          }}
          setShowUploadQuotation={(state) => {
            setShowUploadQuotation(state);
          }}
          isProjectLeaderOrCreatedBy={() =>
            isProjectLeaderOrCreatedBy(selectedProject)
          }
          handleSelectUser={handleSelectUser}
          Quotes={Quotes}
        />
      )}

      {showConversationForm && (
        <ConversationForm
          selectedTask={selectedTask}
          showConversationForm={showConversationForm}
          projectUsersList={selectedTask.assignedTo}
          setShowConversationForm={(state) => {
            setShowConversationForm(state);
          }}
        />
      )}

      {showUploadQuotation && (
        <UploadQuotation
          selectedTask={selectedTask}
          showUploadQuotation={showUploadQuotation}
          projectUsersList={selectedTask.assignedTo}
          setShowUploadQuotation={(state) => {
            setShowUploadQuotation(state);
          }}
          updateQuotes={(data) => {
            console.log(data);
            // setQuotes((oldValue) => {
            //   return [...oldValue, data]
            // });
          }}
        />
      )}

      {/*<UploadToModal />*/}

      <div
        ref={colorPickerRef}
        className={`position-fixed custom-color-picker-holder shadow ${
          !colorPickerState.showColorPicker ? "d-none" : ""
        }`}
        style={{
          top: colorPickerState.colorPickerStyle.top,
          left: colorPickerState.colorPickerStyle.left,
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <span>Pick a Color</span>
          <button
            className="btn p-0"
            onClick={() =>
              setColorPickerState({
                ...colorPickerState,
                showColorPicker: false,
              })
            }
          >
            <CloseIcon />
          </button>
        </div>
        <div className={`custom-color-picker`}>
          {colors.map((color, index) => (
            <div
              key={index}
              className="select-color-box border"
              style={{ background: color.colorCode }}
              onClick={() => handleColorPicker(color.colorCode)}
            >
              {colorPickerState.checkedColor === color.colorCode ? (
                <ColorCheckedIcon />
              ) : null}
            </div>
          ))}
        </div>
      </div>

      {
        <BoardSettingsContainer
          // isClosed={showBoardSettings}
          // setShowBoardSettings={setShowBoardSettings}
          selectedProject={selectedProject}
          toggleProjectNotification={toggleProjectNotification}
          calculateProjectProgress={calculateProjectProgress}
        />
      }

      {/*<FileModal />*/}

      {/*<button
        className="btn btn-primary"
        onClick={() =>
          showToast({
            message: {
              title: "Project Created",
              description: "You have successfully created a new project!",
            },
            type: "success",
          })
        }
      >
        Info
      </button>
      */}

      <div
        ref={mentionRef}
        className={`${!usersListStyle.isUsersListShow ? "d-none" : ""}`}
      >
        <Mention
          handleSelectUser={handleSelectUser}
          key={usersListStyle.isUsersListShow}
          usersList={usersList}
          style={{ ...usersListStyle }}
        />
      </div>

      {user._id && !user.hasPersonalised ? (
        <div className="board-settings-tooltip">
          <span> Personalize your board</span>
          <button
            onClick={() => setHasPersonalised(user._id)}
            type="button"
            className="btn p-1"
            title="Close"
          >
            <CloseIcon />
          </button>
        </div>
      ) : null}
    </section>
  );
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  token: state.userReducer.token,
  hasAccountExpired: state.userReducer.hasAccountExpired,
  fileProgress: state.uploadFileReducer.fileProgress,
  boardSettings: state.boardSettingsReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setPersonalised: () => dispatch(setPersonalised()),
    setUploadFile: (files) => dispatch(setUploadFile(files)),
    saveSettings: (x) => dispatch(saveSettings(x)),
    populateNotifications: (num, type, description) =>
      dispatch(populateNotifications(num, type, description)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Board);
