import { format } from "date-fns";
import { cloneDeep, toArray } from "lodash";
import CloseIcon from "mdi-react/CloseIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import "../../../assets/scss/projectDetails.scss";
import { retryUploadFile } from "../../../store/actions/uploadFileActions";
import Avatar from "../components/Avatar";
import { ReUploadIcon } from "../components/Icons";
import {
  AppRegExp,
  GetFileIcon,
  showToast,
  truncateFileName,
  calculateFileSize,
  downloadFile,
  removeFirstCharacter,
  setFileUrl,
  progressTheme,
  microsoftOfficeFileExtensions,
} from "../utils";
import uploadClient from "../../../helper/uploadClient";
import userReducer from "../../../store/reducers/userReducer";
import { setShowLoginModal } from "../../../store/actions";
import { Link } from "react-router-dom";
import FileModal from "./FileModal";
import { socket } from "../../../sockets";
import { LockIcon, ShareBg } from "../../topbar/Icons";
import { isUserInProjectApi } from "../../../services/projectServices";
import Mention from "../components/Mention";
import DownloadIcon from "mdi-react/DownloadIcon";
import eventBus from "../../../helper/EventBus";

function ProjectDetailsModal({
  user,
  showProjectDetailsModal,
  setShowProjectDetailsModal,
  project,
  ongoingUploads,
  setShowProjectEditModal,
  setProjectEditStage,
  gotoBoard,
  fileProgress,
  handleFileUpload,
  retryUploadFile,
  canEdit,
  showGotoBoard,
  setShowGotoBoard,
  usersList,
  showUsersList,
  isShared,
  setShowLoginModal,
  usersListStyle,
  token,
  allProjectMembers,
}) {
  const watcherServerUrl = process.env.REACT_APP_FILE_WATCHER_SERVER;
  const cloudDriveFrontEnd = process.env.REACT_APP_CLOUD_DRIVE_FRONTEND;
  const [files, setFiles] = useState({});
  const [ogFiles, setOgFiles] = useState([]);
  const [showFileModal, setShowFileModal] = useState(false);
  const [data, setData] = useState({
    projectFilesDataList: [],
    indexInView: 0,
    imageFiles: [],
  });

  const [hasClickedJoin, setHasClickedJoin] = useState(false);
  const [hasBeenAccepted, setHasBeenAccepted] = useState(false);

  useEffect(() => {
    setFiles({
      ...fileProgress,
    });
    return () => {};
  }, [fileProgress]);

  useEffect(() => {
    setOgFiles([...(project.filesData ? project.filesData : [])]);
    return () => {};
  }, [project.filesData]);

  useEffect(() => {
    const imageFiles = [
      ...toArray(files).filter((file) => file.projectId === project._id),
      ...ogFiles,
    ].filter(
      (file) =>
        AppRegExp.isImageFullPath(`.${file.extension}`) ||
        AppRegExp.isImageFullPath(`${file?.ext}`)
    );

    console.log("og Files", imageFiles);
    setData({ ...data, imageFiles: [...imageFiles] });
  }, [files, ogFiles]);

  const handleAccepted = useCallback(
    (res) => {
      // alert("test");
      // console.log(res);
      if (res?.success && res.projectId === project._id) {
        setHasBeenAccepted(true);
        showToast({
          message: {
            title: "Your request to join this project has been accepted",
          },
          type: "success",
        });
      }
    },
    [project]
  );

  useEffect(() => {
    socket.on(`fx:request-to-join-project-accepted`, handleAccepted);

    return () => {
      socket.off(`fx:request-to-join-project-accepted`, handleAccepted);
    };
  }, [handleAccepted]);

  const updateProjectFile = (event) => {
    let { projectFilesDataList } = data;
    let fileInput = event.target;
    let files = fileInput.files;
    let fl = files.length;
    let i = 0;
    while (i < fl) {
      const projectFile = {
        originalName: "",
        uniqueName: "",
        extension: "",
        size: "",
      };
      const file = files[i];
      const name = file.name;
      const lastDot = name.lastIndexOf(".");
      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);
      const size = file.size;
      let currentDate = new Date();
      currentDate = currentDate.getTime();
      projectFile.originalName = fileName;
      projectFile.uniqueName = currentDate;
      projectFile.extension = ext;
      if (size < 1024) {
        projectFile.size = size + " B";
      } else if (size >= 1024 && size < 1024 * 1024) {
        projectFile.size = (size / 1024).toFixed(2) + " KB";
      } else {
        projectFile.size = (size / 1024 / 1024).toFixed(2) + " MB";
      }

      projectFile.file = file;
      projectFilesDataList = projectFilesDataList.concat(projectFile);

      if (i == fl - 1) {
        console.log(projectFilesDataList);
        handleFileUpload({
          folder_id: "",
          project: {
            ...project,
            files: [...projectFilesDataList],
          },
        });
      }
      i++;
    }
  };

  const isUserInProject = () => {
    return (
      project.projectLeader.find((userEl) => userEl._id === user._id) ||
      project.team.find((userEl) => userEl._id === user._id)
    );
  };

  const joinProject = () => {
    socket.emit(
      "request-to-join-project",
      {
        projectId: project._id,
        token,
      },
      (response) => {
        console.log(response);
        if (response.success) {
          setHasClickedJoin(true);
          showToast({
            message: {
              title: "Your request to join this project has been sent",
            },
            type: "info",
          });
        } else {
          showToast({
            message: {
              title: "Unable to send request",
            },
            type: "error",
          });
        }
      }
    );
  };

  const handleJoinProject = async () => {
    let ready = false;
    if (Object.keys(user).length) {
      // setTimeoutjoin

      setInterval(async () => {
        if (ready) return;
        const res = await isUserInProjectApi({
          projectId: project._id,
          userId: user._id,
        });
        if (res.project) {
          ready = true;
          setHasBeenAccepted(true);
          showToast({
            message: {
              title: "Your request to join this project has been accepted",
            },
            type: "success",
          });
        }
        // console.log("timeout", res);
      }, 5000);

      // request to join project
      if (isUserInProject()) {
        showToast({
          message: {
            title: "You are already part of this project",
          },
          type: "info",
        });
        return;
      }

      joinProject();
    } else {
      // login
      setShowLoginModal(true);
    }
  };

  const handleClose = () => {
    if (!isShared) setShowProjectDetailsModal(false);
  };

  const handleShowFile = (file) => {
    file.cloud_id = file?.cloud_id ? file.cloud_id : file._id;
    file.originalName = file?.originalName ? file.originalName : file.name;
    file.extension = file?.extension ? file.extension : file.ext;

    if (file.extension.startsWith(".")) {
      file.extension = file.extension.substring(1);
    }

    if (AppRegExp.isImageFullPath(`.${file.extension}`)) {
      const foundFileIndex = data.imageFiles.findIndex(
        (fileEl) => fileEl._id === file._id
      );
      if (foundFileIndex !== -1) {
        setData({ ...cloneDeep(data), indexInView: foundFileIndex });
        setShowFileModal(true);
      }
    } else if (file.extension === "pdf") {
      const fileUrl = `${watcherServerUrl}/files/${file.cloud_id}/${file.originalName}.${file.extension}`;
      eventBus.dispatch("onViewPdf", { fileUrl, ...file });
    } else if (microsoftOfficeFileExtensions.includes(file.extension)) {
      const fileUrl = cloudDriveFrontEnd + "/group-edit-doc/" + file.cloud_id;
      window.open(fileUrl, "_blank");
    } else {
      const fileUrl = `${watcherServerUrl}/files/${file.cloud_id}/${file.originalName}.${file.extension}?view`;
      window.open(fileUrl, "_blank");
    }
  };

  const arrangeFileData = (files) => {
    return cloneDeep(files).map((file) => {
      return {
        ...file,
        cloud_id: file?.cloud_id ? file.cloud_id : file._id,
        originalName: file?.originalName ? file.originalName : file.name,
        extension: file?.extension
          ? file.extension
          : removeFirstCharacter(file.ext),
      };
    });
  };

  return (
    <>
      <Modal
        show={showProjectDetailsModal}
        onHide={() => handleClose()}
        centered
        animation={false}
        dialogClassName="project-detail-content"
        backdropClassName={`global-backdrop max-z-index ${
          isShared ? "bg-dark-blue" : ""
        }`}
        aria-labelledby=""
        enforceFocus={false}
      >
        {isShared &&
        !project?.sharable?.enabled &&
        !isUserInProject() &&
        !hasBeenAccepted ? (
          <div className="disabled-share d-flex justify-content-center align-items-center w-100 h-100 position-absolute overflow-hidden">
            <ShareBg className="bg" />
            <div className="content text-center">
              <img
                src="../../assets/svg/undraw_Organizing_projects_0p9a.svg"
                className="empty-svg my-3"
              />
              <p className="h3 mb-3">This project is private</p>
              <button
                onClick={() => handleJoinProject()}
                disabled={hasClickedJoin}
                className="btn btn-primary btn-lg"
              >
                {hasClickedJoin ? "Awaiting Response..." : "Request to join"}
              </button>
            </div>
          </div>
        ) : (
          <>
            <Modal.Header>
              <Modal.Title id="">Project Details</Modal.Title>

              {!isShared && (
                <button id="close-btn" onClick={() => handleClose()}>
                  <CloseIcon />
                </button>
              )}
            </Modal.Header>
            <Modal.Body>
              <div className="project-header d-flex justify-content-between">
                <div className="title">
                  <h1>{project.name}</h1>
                  <span>Starting date: {project?.startDate}</span>
                  <span>Due date: {project?.endDate}</span>
                  {/*<span>
                    Duration: {project.duration} {project.durationType}
                  </span>*/}
                </div>
                {canEdit && !isShared && (
                  <div className="action">
                    <button
                      className="btn btn-outline-primary"
                      onClick={() => {
                        setProjectEditStage(1);
                        setShowProjectEditModal(true);
                      }}
                    >
                      Edit Detail
                    </button>
                  </div>
                )}
              </div>

              <div className="project-details d-flex flex-column flex-md-row mt-2 border-bottom">
                <div className="border-right pr-4">
                  <p className="mb-3">{project.description}</p>

                  <div className="d-flex members">
                    <div>
                      <span className="title">Team</span>
                      <div className="d-flex">
                        {allProjectMembers(project) &&
                          allProjectMembers(project)
                            .slice(0, 4)
                            .map((user, index) => (
                              <Avatar
                                key={index}
                                width={40}
                                height={40}
                                user={user}
                                zIndex={10 - index}
                                badgeStyle={{
                                  right: "-0.6rem",
                                }}
                              />
                            ))}

                        {allProjectMembers(project) &&
                        allProjectMembers(project).length > 4 ? (
                          <Avatar
                            width={40}
                            height={40}
                            isCounter={true}
                            count={allProjectMembers(project).length - 4}
                            zIndex={1}
                            usersList={usersList}
                            showUsersList={showUsersList}
                          />
                        ) : null}
                      </div>
                    </div>

                    {/*}
                    <div>
                      <span className="title">Member</span>
                      <div className="d-flex">
                        {project?.team &&
                          project?.team
                            .slice(0, 5)
                            .map((user, index) => (
                              <Avatar
                                key={index}
                                width={40}
                                height={40}
                                user={user}
                                zIndex={10 - index}
                              />
                            ))}

                        {project?.team && project?.team.length > 3 ? (
                          <Avatar
                            width={40}
                            height={40}
                            isCounter={true}
                            usersList={usersList}
                            count={project?.team.length - 3}
                            zIndex={1}
                            showUsersList={showUsersList}
                          />
                        ) : null}
                      </div>
                    </div>
        */}
                  </div>
                </div>
                <div className="desc pl-4">
                  <div>
                    <p>Client</p>
                    <span>{project.client?.companyName || "..."}</span>
                  </div>

                  <div>
                    <p>Project Cost</p>
                    <span>{project.cost || "..."}</span>
                  </div>

                  <div>
                    <p>Project Status</p>
                    <span>{project.status || "..."}</span>
                  </div>

                  <div>
                    <p>Priority</p>
                    <span>{project.priority || "..."}</span>
                  </div>

                  <div>
                    <p>Assigned Team</p>
                    <span>{project.teamId?.name || "..."}</span>
                  </div>
                </div>
              </div>

              <div className="project-attachments d-flex justify-content-between">
                <div className="title">
                  <h2>Project Attachment</h2>
                </div>

                {canEdit && !isShared && (
                  <div className="action">
                    <label className="btn btn-outline-primary" htmlFor="file">
                      + Add File
                      <input
                        type="file"
                        name="file"
                        multiple
                        id="file"
                        className="d-none"
                        onChange={updateProjectFile}
                      />
                    </label>
                  </div>
                )}
              </div>

              <div className="project-files">
                {isShared ? (
                  <div className="w-100 d-flex align-items-center justify-content-center p-2">
                    <div className="text-center" style={{ color: "#A7B9CB" }}>
                      <LockIcon width="50" height="50" />
                      <p className="h4 mt-2">Files are private</p>
                    </div>
                  </div>
                ) : (
                  <div className="files">
                    <>
                      {[
                        ...toArray(files).filter(
                          (file) => file.projectId === project._id
                        ),
                        ...ogFiles,
                      ].map((file, index) => (
                        <div
                          key={index}
                          className="grid-item d-flex justify-content-between align-items-center p-cursor"
                          style={file.error ? { background: "#FFFAFA" } : {}}
                          onClick={() => {
                            //  console.log(file);
                            if (file.status || file._id) handleShowFile(file);
                          }}
                        >
                          <div className="d-flex align-items-center">
                            {AppRegExp.isImageFullPath(
                              `${file.originalName}.${file.extension}`
                            ) && file.cloud_id ? (
                              <div className="image-holder">
                                <img
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                  }}
                                  alt=""
                                  src={setFileUrl(file)}
                                />
                              </div>
                            ) : (
                              <GetFileIcon
                                file={{
                                  ...file,
                                  extension: file?.extension
                                    ? file.extension
                                    : removeFirstCharacter(file.ext),
                                }}
                              />
                            )}

                            <div className="d-flex flex-column file-details">
                              <span>{truncateFileName(file)}</span>
                              {file.error ? (
                                <span
                                  style={file.error ? { color: "#FC6D6C" } : {}}
                                >
                                  Upload failed
                                </span>
                              ) : (
                                <span>{calculateFileSize(file.size)}</span>
                              )}
                            </div>
                          </div>

                          {file?.progress && !file?.status ? (
                            <Progress
                              /*onClick={() =>
                                uploadClient.dispatchEvent("cancel", file.id)
                              }*/
                              type="circle"
                              percent={file.progress}
                              strokeWidth={10}
                              width={32}
                              symbolClassName="cancel"
                              theme={progressTheme}
                            />
                          ) : null}

                          {file.error ? (
                            <ReUploadIcon
                              onClick={() => retryUploadFile(file.id)}
                            />
                          ) : null}

                          {/*!file?.file || file.status ? (
                            <span className="close d-none">✖</span>
                          ) : null*/}

                          {!file?.file || file.status ? (
                            <DownloadIcon
                              className="download"
                              onClick={() => downloadFile(file)}
                            />
                          ) : null}
                        </div>
                      ))}
                    </>

                    {/*
              
              <div className="grid-item d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <svg
                    className="file-icon"
                    width="30"
                    height="38"
                    viewBox="0 0 30 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.6666 0.666748H3.99992C3.02746 0.666748 2.09483 1.05306 1.40719 1.74069C0.71956 2.42832 0.333252 3.36095 0.333252 4.33341V33.6667C0.333252 34.6392 0.71956 35.5718 1.40719 36.2595C2.09483 36.9471 3.02746 37.3334 3.99992 37.3334H25.9999C26.9724 37.3334 27.905 36.9471 28.5926 36.2595C29.2803 35.5718 29.6666 34.6392 29.6666 33.6667V11.6667L18.6666 0.666748ZM10.4129 26.6817C9.84642 27.2134 9.01042 27.4517 8.03692 27.4517C7.84816 27.4538 7.65949 27.4428 7.47225 27.4187V30.0331H5.83325V22.8171C6.57292 22.7067 7.3203 22.6565 8.06808 22.6667C9.08925 22.6667 9.81525 22.8611 10.3048 23.2516C10.7704 23.6219 11.0858 24.2287 11.0858 24.9437C11.0839 25.6624 10.8456 26.2692 10.4129 26.6817ZM17.3924 29.1659C16.6224 29.8057 15.4509 30.1101 14.0191 30.1101C13.1611 30.1101 12.5543 30.0551 12.1418 30.0001V22.8189C12.8817 22.7109 13.6288 22.66 14.3766 22.6667C15.7644 22.6667 16.6664 22.9161 17.3704 23.4477C18.1313 24.0124 18.6079 24.9126 18.6079 26.2051C18.6079 27.6039 18.0964 28.5701 17.3924 29.1659ZM24.1666 24.0784H21.3579V25.7486H23.9832V27.0942H21.3579V30.0349H19.6969V22.7217H24.1666V24.0784ZM18.6666 13.5001H16.8333V4.33341L25.9999 13.5001H18.6666Z"
                      fill="#FE5B52"
                    />
                  </svg>

                  <div className="d-flex flex-column file-details">
                    <span>pro...ent.pdf</span>
                    <span>1,2 Mb</span>
                  </div>
                </div>

                <span className="close">✖</span>
              </div>
              <div className="grid-item d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <svg
                    className="file-icon"
                    width="30"
                    height="38"
                    viewBox="0 0 30 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.6666 0.666748H3.99992C3.02746 0.666748 2.09483 1.05306 1.40719 1.74069C0.71956 2.42832 0.333252 3.36095 0.333252 4.33341V33.6667C0.333252 34.6392 0.71956 35.5718 1.40719 36.2595C2.09483 36.9471 3.02746 37.3334 3.99992 37.3334H25.9999C26.9724 37.3334 27.905 36.9471 28.5926 36.2595C29.2803 35.5718 29.6666 34.6392 29.6666 33.6667V11.6667L18.6666 0.666748ZM10.4129 26.6817C9.84642 27.2134 9.01042 27.4517 8.03692 27.4517C7.84816 27.4538 7.65949 27.4428 7.47225 27.4187V30.0331H5.83325V22.8171C6.57292 22.7067 7.3203 22.6565 8.06808 22.6667C9.08925 22.6667 9.81525 22.8611 10.3048 23.2516C10.7704 23.6219 11.0858 24.2287 11.0858 24.9437C11.0839 25.6624 10.8456 26.2692 10.4129 26.6817ZM17.3924 29.1659C16.6224 29.8057 15.4509 30.1101 14.0191 30.1101C13.1611 30.1101 12.5543 30.0551 12.1418 30.0001V22.8189C12.8817 22.7109 13.6288 22.66 14.3766 22.6667C15.7644 22.6667 16.6664 22.9161 17.3704 23.4477C18.1313 24.0124 18.6079 24.9126 18.6079 26.2051C18.6079 27.6039 18.0964 28.5701 17.3924 29.1659ZM24.1666 24.0784H21.3579V25.7486H23.9832V27.0942H21.3579V30.0349H19.6969V22.7217H24.1666V24.0784ZM18.6666 13.5001H16.8333V4.33341L25.9999 13.5001H18.6666Z"
                      fill="#FE5B52"
                    />
                  </svg>

                  <div className="d-flex flex-column file-details">
                    <span>pro...ent.pdf</span>
                    <span>1,2 Mb</span>
                  </div>
                </div>

                {/* <CloseIcon /> 

                <Progress
                  type="circle"
                  percent={70}
                  strokeWidth={10}
                  width={26}
                  symbolClassName="cancel"
                  theme={{
                    success: {
                      symbol: "✖",
                      trailColor: "#A7B9CB",
                      color: "#61DB99",
                    },
                    active: {
                      symbol: "✖",
                      trailColor: "#A7B9CB",
                      color: "#61DB99",
                    },
                    default: {
                      symbol: "✖",
                      trailColor: "#A7B9CB",
                      color: "#61DB99",
                    },
                  }}
                />
              </div>
                */}
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer bsPrefix="modal-footer update-modal-footer">
              {showGotoBoard ? (
                <Button className="grand-btn" onClick={() => handleClose()}>
                  Go to Project Board
                </Button>
              ) : null}

              {isShared ? (
                <>
                  {!isUserInProject() && !hasBeenAccepted ? (
                    <Button
                      className="grand-btn"
                      onClick={() => handleJoinProject()}
                      disabled={hasClickedJoin}
                    >
                      {hasClickedJoin ? "Awaiting Response..." : "Join"}
                    </Button>
                  ) : (
                    <a
                      className="btn btn-primary grand-btn"
                      href={`/task-board?projectId=${project._id}&m=f`}
                    >
                      Go to Project Board
                    </a>
                  )}
                </>
              ) : null}
            </Modal.Footer>
          </>
        )}
        {showFileModal && (
          <FileModal
            key={data.imageFiles}
            indexInView={data.indexInView}
            showFileModal={showFileModal}
            setShowFileModal={(isOpen) => setShowFileModal(isOpen)}
            files={arrangeFileData(data.imageFiles)}
            hideAsComment={true}
          />
        )}

        {
          <div className={`${!usersListStyle.isUsersListShow ? "d-none" : ""}`}>
            <Mention
              key={usersListStyle.isUsersListShow}
              usersList={usersList}
              style={{ ...usersListStyle }}
            />
          </div>
        }
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.userReducer.user,
  token: state.userReducer.token,
  hasAccountExpired: state.userReducer.hasAccountExpired,
  fileProgress: state.uploadFileReducer.fileProgress,
});

const mapDispatchToProps = (dispatch) => {
  return {
    retryUploadFile: (fileId) => dispatch(retryUploadFile(fileId)),
    setShowLoginModal: (isShown) => dispatch(setShowLoginModal(isShown)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailsModal);
